import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogBackdrop, useDialogState } from "reakit/Dialog";
import { Button, Input, Container } from "reactstrap";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"; // Firebase config

const LevelRewards = ({ levelRewards, removeLevelRecord }) => {
  const addDialogState = useDialogState();
  const [newLevelReward, setNewLevelReward] = useState({
    name: "",
    level: 0,
    criteria: 0,
    description: "",
    image: null,
    reward: "",
    type: "digital", // Default value for the type field
  });
  const [selectedReward, setSelectedReward] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageUpload = async () => {
    if (!imageFile) return null;
    try {
      const storageRef = ref(storage, `levelRewards/${imageFile.name}`);
      const uploadTaskSnapshot = await uploadBytes(storageRef, imageFile);
      return getDownloadURL(uploadTaskSnapshot.ref);
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const handleSaveLevelReward = async (e) => {
    e.preventDefault();
  
    try {
      let imageUrl = null;
  
      // Upload new image or retain the existing one
      if (imageFile) {
        imageUrl = await handleImageUpload();
      } else if (newLevelReward.image) {
        imageUrl = newLevelReward.image;
      }
  
      // Prepare sanitized data, including type
      const rewardData = sanitizeData({
        name: newLevelReward.name || "",
        level: newLevelReward.level || 0,
        criteria: newLevelReward.criteria || 0,
        description: newLevelReward.description || "",
        image: imageUrl || null,
        reward: newLevelReward.reward || "",
        type: newLevelReward.type || "digital", // Include type here
      });
  
      if (selectedReward) {
        // Update existing record
        const docRef = doc(db, "levelRewards", selectedReward.firebaseId || selectedReward.id);
        await updateDoc(docRef, rewardData);
      } else {
        // Add new record
        await addDoc(collection(db, "levelRewards"), rewardData);
      }
  
      // Reset modal and inputs
      closeModal();
    } catch (error) {
      console.error("Error saving level reward:", error.message || error);
    }
  };
  

  const sanitizeData = (data) => {
    const sanitized = {};
    for (const key in data) {
      if (data[key] !== undefined && data[key] !== null) {
        sanitized[key] = data[key];
      }
    }
    return sanitized;
  };

  const closeModal = () => {
    setNewLevelReward({
      name: "",
      level: 0,
      criteria: 0,
      description: "",
      image: null,
      reward: "",
      type: "digital", // Reset to default
    });
    setImageFile(null);
    setSelectedReward(null);
    addDialogState.hide();
  };

  const handleEditReward = (reward) => {
    setSelectedReward(reward);
    setNewLevelReward({
      name: reward.name || "",
      level: reward.level || 0,
      criteria: reward.criteria || 0,
      description: reward.description || "",
      image: reward.image || null,
      reward: reward.reward || "",
      type: reward.type || "digital", // Ensure type is set
    });
    addDialogState.show();
  };
  

  return (
    <Container>
      <Button onClick={addDialogState.show} style={{ marginBottom: "10px" }}>
        Add Level Reward
      </Button>

      <div className="bonusholder">
        {levelRewards?.map((d, i) => (
          <div className="bonus-reward col-card" key={d.id + d.name}>
            <div><strong>{d.name}</strong></div>
            <div><strong>Level: {i + 1}</strong></div>
            <div>Total Points: {d.criteria}</div>
            <div>{d.description}</div>
            <div>Reward: {d.reward || "N/A"}</div>
            <div>Type: {d.type || "N/A"}</div> {/* Display type */}
            <div>
              {d.image && <img src={d.image} alt={d.name} style={{ width: "50px", height: "50px" }} />}
            </div>
            <div>
              <span className="rightbtnpad pointer" onClick={() => handleEditReward(d)}>
                <FontAwesomeIcon icon="edit" className="mr-3" /> Edit
              </span>
              <span className="mr-3 highz pointer" onClick={(e) => removeLevelRecord(e, d.firebaseId)}>
                <FontAwesomeIcon icon="trash" className="mr-3" /> Delete
              </span>
            </div>
          </div>
        ))}
      </div>

      <DialogBackdrop {...addDialogState} className="backstyle">
        <Dialog className="refix" {...addDialogState} aria-label="Add/Edit Level Reward">
          <h3>{selectedReward ? "Update Level Reward" : "Add New Level Reward"}</h3>
          <form onSubmit={handleSaveLevelReward}>
            <label className="full-width">
              Name:
              <Input
                type="text"
                value={newLevelReward.name}
                onChange={(e) => setNewLevelReward({ ...newLevelReward, name: e.target.value })}
                placeholder="Enter reward name"
              />
            </label>
            <label className="full-width">
              Level:
              <Input
                type="number"
                value={newLevelReward.level}
                onChange={(e) => setNewLevelReward({ ...newLevelReward, level: Number(e.target.value) })}
                placeholder="Enter level"
              />
            </label>
            <label className="full-width">
              Criteria:
              <Input
                type="number"
                value={newLevelReward.criteria}
                onChange={(e) => setNewLevelReward({ ...newLevelReward, criteria: Number(e.target.value) })}
                placeholder="Enter criteria points"
              />
            </label>
            <label className="full-width">
              Description:
              <Input
                type="text"
                value={newLevelReward.description}
                onChange={(e) => setNewLevelReward({ ...newLevelReward, description: e.target.value })}
                placeholder="Enter description"
              />
            </label>
            <label className="full-width">
              Reward:
              <Input
                type="text"
                value={newLevelReward.reward}
                onChange={(e) => setNewLevelReward({ ...newLevelReward, reward: e.target.value })}
                placeholder="Enter reward details"
              />
            </label>
            <label className="full-width">
              Type:
              <select
                value={newLevelReward.type}
                onChange={(e) => setNewLevelReward({ ...newLevelReward, type: e.target.value })}
                style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ccc", marginTop: "5px" }}
              >
                <option value="digital">Digital</option>
                <option value="physical">Physical</option>
              </select>
            </label>
            <label className="full-width">
              Image:
              <Input
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </label>
            {newLevelReward.image && (
              <div style={{ margin: "10px 0" }}>
                <img src={newLevelReward.image} alt="Preview" style={{ width: "100px", height: "100px" }} />
              </div>
            )}
            <div style={{ marginTop: "10px" }}>
              <Button type="submit" className="clicklink linker">
                {selectedReward ? "Update" : "Save"}
              </Button>
              <Button type="button" onClick={closeModal} className="clicklink linker greygoose">
                Cancel
              </Button>
            </div>
          </form>
        </Dialog>
      </DialogBackdrop>
    </Container>
  );
};

export default LevelRewards;
