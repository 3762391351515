import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const Redeem = () => {
  const [rewards, setRewards] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [userName, setUserName] = useState('');
  const { user } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    fetchRewards();
    fetchUserPoints();
  }, []);

  const fetchRewards = async () => {
    try {
      const rewardsList = [];
      const snapshot = await getDocs(collection(db, "rewards")); // Replace "rewards" with your collection name
      snapshot.forEach((doc) => {
        const data = doc.data();
        rewardsList.push({ ...data, id: doc.id });
      });
      setRewards(rewardsList);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  };

  const fetchUserPoints = async () => {
    try {
      const userDocRef = collection(db, "purchasedTokens");
      const userDocSnap = await getDocs(userDocRef);
  
      userDocSnap.forEach((doc) => {
        if (doc.id === user.nickname) {
          const userData = doc.data();
          setUserPoints(userData.totalPoints - userData.claimedPoints || userData.totalPoints);
          setUserName(userData.userName || "Unknown"); // Set userName with a fallback to "Unknown"
        }
      });
    } catch (error) {
      console.error("Error fetching user points and userName:", error);
    }
  };
  

  const handleRedeem = (route) => {
    history.push(route);
  };

  return (
    <Container>
        <div className="rewardholder">
        <h1 className="mb-4 new-header" style={{paddingTop: "0px", marginBottom: "0px"}}>Redeemable Rewards</h1>
        
    {/* <h1 className="admin-title" style={{marginBottom: "0px"}}>Redeemable Rewards</h1> */}
      <div className="mb-4 smh updatedh" style={{display: "flex", flexDirection: "column", color: "white"}}>
        <span  style={{paddingRight: "16px"}}>User: <strong  className="newyellow">{userName}</strong></span>
        <span>Available Points: <strong className="newyellow">{userPoints}</strong></span>
      </div>
      <Row>
        {rewards.map((reward) => (
          <Col sm="6" md="4" key={reward.id} className="mb-4">
            <div className="reward-card">
              <h5>{reward.name}</h5>
              <img src={reward.image?.uri} className="redeemimg" />
              <p>Cost: {reward.cost} Points</p>
              <p>{reward.description}</p>
              <Button
                color="primary"
                onClick={() => handleRedeem(reward.route)}
                disabled={userPoints < reward.cost}
              >
                {userPoints >= reward.cost ? "Redeem" : "Not Enough Points"}
              </Button>
            </div>
          </Col>
        ))}
      </Row>
      </div>
    </Container>
  );
};

export default Redeem;
