import React, { useEffect, useState } from "react";
// import logo from "../assets/logo.svg";
import { db } from '../firebase';
import { getFirestore, collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@ramonak/react-progress-bar";
import Gravatar from 'react-gravatar';
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";
import moment from 'moment';
import { Button } from "reakit/Button";
import Avatar from '../utils/avatar';

export const Hero = () => {
  const dialog = useDialogState();
  // const [checkinStatus, setCheckinStatus] = useState(false);
  const [fullLeaderBoard, setLeaderBoard] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currentFilterLimit, setcurrentFilterLimit] = useState(12);
  const [avatarList, setAvatarList] = useState([]);
  const [lastDocument, setLastDocument] = useState(null);
  // const leaderdb = firebase.firestore();
  
  // let fullLeaderBoard = [];
  // const [tempUserDataCheckins, settempUserDataCheckins] = useState([]);
  // let docRef = {};
  // let userWalletInfo = {};
  // const nowTime = moment().format("MMM Do YYYY");
  // const currentMonth = moment(new Date()).format('MMMM');

  // useEffect(() => {
  //   // gettingUserInfoFromFullList();
    
  // }, []);

  useEffect(() => {
    fetchUsers();
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const avatarList = [];
        const snapshot = await getDocs(collection(db, "avatars"));
        snapshot.forEach((doc) => {
          avatarList.push(doc.data());
        });
        setAvatarList(avatarList);
      } catch (error) {
        console.error("Error fetching avatars:", error);
      }
    };

    fetchAvatars();
  }, []);

  useEffect(() => {
		if (!isFetching) return;
		fetchUsers();
	}, [isFetching]);

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    if (bottom && !isFetching) {
        setIsFetching(true);
    }
  };  

  const handleModalScroll = (e) => {
    e.stopPropagation();
  };
  
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const handleModalClick = (d) => {
    setSelectedUser(d); 
    setIsOpen(true)
  }
  // const truncateName = (name) => {
  //   if (name === undefined) {
  //     return;
  //   }
  //   return name.substring(0, 6) + "...";
  // }

  // const levelMax = (level) => {
  //   rewards.forEach((reward) => {
  //     if (reward.level === level + 1) {
  //       setmaxLevel(reward.criteria);
  //     }
  //     // setting first level if its missing for some reason
  //     if (level == null) {
  //       setmaxLevel(rewards[1].criteria);
  //     }
  //   });
  // }
  const fetchUsers = async () => {
    const leaderboardRef = collection(db, "purchasedTokens");
    try {
      let q = query(leaderboardRef, orderBy('totalPoints', 'desc'));

      if (lastDocument) {
        q = query(q, startAfter(lastDocument));
      }

      q = query(q, limit(10));

      const snapshot = await getDocs(q);
      const newUsers = snapshot.docs.map((doc) => {
        const userData = doc.data();
        const formingTime = userData.created.toDate();
        // Modify the data or add additional properties here
        const modifiedData = {
          ...userData,
          id: doc.id,
          membersince: moment(formingTime).format("MM/DD/YYYY"),
          lastcheckin: moment(userData.metaData?.checkins[0]).format("MM/DD/YYYY")
        };

        return modifiedData;
      });

      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      if (fullLeaderBoard.length > 0) {
        setLeaderBoard((prevUsers) => [...prevUsers, ...newUsers]);
      } else {
        setLeaderBoard(newUsers);
      }
      setLastDocument(lastVisible);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  

  const setSuffix = (i) => {
    var j = i + 1 % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}
  return (
    <div className="text-center hero my-5 leadery">
    {/* <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" /> */}
    <div className="mb-4 smh">Winter Season</div>
    <h1 className="mb-4" style={{paddingTop: "0px"}}>Leaderboard</h1>
    <div className="leader-contain">

      {fullLeaderBoard?.map((d, i) => (
        <div className="container-lead" key={i}>
           <DialogDisclosure className="open-dia stia twe" {...dialog} onClick={() => handleModalClick(d)}>
            <div className={i <= 2 ? 'active-leader-item' : 'leader-item'}>
              
              <div className="leader-rank">
                <span className="rank-num">{i + 1}</span> 
                {i === 0 &&
                  <FontAwesomeIcon icon="award" className="gold ico stee" /> 
                }
                {i === 1 &&
                  <FontAwesomeIcon icon="award" className="silver ico stee" />
                }
                {i === 2 &&
                  <FontAwesomeIcon icon="award" className="bronze ico stee" /> 
                }
                {/* <span className="leader-su"qrffix">{setSuffix(i)}</span> */}
              </div>
              <Avatar address={d.metaData.email} avatar={d.avatar ? d.avatar : undefined} size="small"/>
              {/* <Gravatar email={d.metaData.email} className="gravy" />  */}
              <div className="leader-name">
                <span className={d.userName ? 'hidden' : 'stuff'}>{d.metaData.nickname.substring(0, 13)}{d.metaData.nickname.length > 13 && '..'}</span>
                {d.userName && 
                  <span className="stuff">{d.userName.substring(0, 13)}{d.userName.length > 13 && '..'}</span>
                }
                <div className="leader-level">
                  <span className="level-lev">Level:</span>
                  <span>{d.level}</span>
                  {!d.level &&<span>1</span>}
                </div>
              </div>
              <div className="point-holder">
                <span className="value">{d.totalPoints > 0 ? d.totalPoints : 0}</span>
                <span className="label">Points</span>
              </div>
            </div>
          </DialogDisclosure>          
        </div>
      ))}
    {/* <table className="leader-table">
        <thead>
            <tr>
                <th>Rank</th>
                <th className="smalltd"></th>
                <th className="leftalign">Username</th>
                <th className="leftalign">Level</th>
            </tr>
        </thead>
        <tbody>
        {fullLeaderBoard?.slice(0, 20).map((c, i) => (
            <tr key={i}>
              <td>
                <div className="ranky">
                {i === 0 &&
                  <FontAwesomeIcon icon="award" className="gold ico" /> 
                }
                {i === 1 &&
                <FontAwesomeIcon icon="award" className="silver ico" /> 
                }
                {i === 2 &&
                <FontAwesomeIcon icon="award" className="bronze ico" /> 
                }
                {i >= 3 &&
                <FontAwesomeIcon icon="minus" className="bronze ico" /> 
                }
                <span>{i + 1}</span>
                </div>
              </td>
              <td align="center" className="smalltd">
                <div  className="pranky">
                
                  <Gravatar email={c.metaData.email} className="gravy" /> 
                
                </div>
              </td>
              <td>
              <div className="sranky">
                  <span className={c.userName && 'hidden'}>{c.metaData.nickname.substring(0, 17)}{c.metaData.nickname.length >= 17 && '..'}</span>
                  {c.userName && 
                    <span>{c.userName.substring(0, 17)}{c.userName.length >= 17 && '..'}</span>
                  }
              </div>
              </td>
              <td align="left">
              <span>{c.level}</span>
              {!c.level &&
               <span>1</span>
              }
                </td>
            </tr>
        ))}
        </tbody>
    </table> */}
    </div>
    {/* <div className="section-container">
      <p className="lead">
        Upcoming Point Earning Events:
      </p>
    </div>
    <div className="section-container">
      <p className="lead">
        Past Reward Drops:
      </p>
    </div> */}

<DialogBackdrop {...dialog}  className="backstyle">
  
    <Dialog className={`refix modal-animation ${isOpen ? 'open' : ''}`} {...dialog} aria-label="Welcome" onScroll={handleModalScroll}>
      <div className="flexholder zeropadbot">
        <div className="gravhold">
          <Avatar address={selectedUser?.metaData?.email} avatar={selectedUser.avatar ? selectedUser.avatar : undefined}/>
            <div className="leader-level levler">
              <span className="level-lev">Level:</span>
              <span>{selectedUser?.level}</span>
              {!selectedUser.level &&<span>1</span>}
            </div>
        </div>
        <h3 className="claimh claimg claimdeez zeropadbot">
        {selectedUser.userName ? selectedUser?.userName : selectedUser?.metaData?.nickname}
        
        {/* <span className={selectedUser?.userName ? 'hidden' : 'stuff'}>{selectedUser?.metaData.nickname.substring(0, 17)}{selectedUser?.metaData.nickname.length >= 17 && '..'}</span> */}
        {/* <p className="darkwallet subster">{ truncateWallet(selectedUser?.walletAddress)}</p> */}
      </h3>
      </div>
      

      <Button className="closebtn" onClick={dialog.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
      <div className="flutarsky">
        <div className="childtarsky">
          <div className="rizzow">
            <p className="p-tag minus-bot memsince">Member Since <br /><strong>{selectedUser?.membersince}</strong></p>
            <p className="p-tag minus-bot plus lastdance">Last Checkin <br /><strong>{selectedUser?.lastcheckin}</strong></p>
          </div>
          <div className="rizzow">
            
            
            <div className="point-holder">
            <span className="value">{selectedUser.totalPoints > 0 ? selectedUser.totalPoints : 0}</span>
            <span className="label">Points</span>
          </div>
          </div>
          
          
          
          
          {/* <ProgressBar className="progress-bar-hawk" completed={selectedUser.metaData.checkins.length} customLabel=" " maxCompleted={maxLevel} />
          <p className="progress-line">{maxLevel - selectedUser.metaData.checkins.length} more points needed for Level {selectedUser?.level ? selectedUser?.level + 1 : 2}</p> */}
          <div className="stat-holders">
            <p className="p-tag">Checkins <br /> <strong>{selectedUser?.metaData?.checkins.length}</strong></p>
            <p className="p-tag bordera">Level <br /> <strong>{selectedUser?.level ? selectedUser?.level : 1}</strong></p>
            <p className="p-tag">Rewards <br /><strong>{selectedUser?.rewards?.length}</strong></p>
          </div>

          <div className="badge-container">
            <span className="badge-label padbad2">earned badges:</span>
            <div className="badges">
            {selectedUser?.rewards?.map((d, i) => (
              <div className="badge" key={i+d.image}>
                  <div 
                    style={{
                      backgroundImage: `url(${d.image})`,
                      backgroundSize: "cover",
                    }}
                    className={'leader-rank newbadge newerbadge'}></div>
              </div>
            ))}
            </div>
            {/* <div className="twitter-stuff">
              <a href="https://twitter.com/nfdaddys?ref_src=twsrc%5Etfw" target="_blank" className="twitter-follow-button" data-show-count="false">Follow @nfdaddys</a>
            </div> */}
          </div>
        </div>
      </div>
    
    </Dialog>
    </DialogBackdrop>
    
    </div>

  );
};


export default Hero;