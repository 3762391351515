import React, { useEffect, useState } from "react";
// import logo from "../assets/logo.svg";
import { db } from '../firebase';
import { collection, onSnapshot, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Gravatar from 'react-gravatar';
import { Button } from "reakit/Button";
import { Link } from "react-router-dom";
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
} from "reakit/Dialog";
import Avatar from '../utils/avatar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Lobby = () => {

  // const [checkinStatus, setCheckinStatus] = useState(false);
  const [fullLeaderBoard, setLeaderBoard] = useState([]);
  const [adminAllowed, setadminAllowed] = useState(false);
  const [productList, setProductList] = useState([]);
  const [currentOrder, setcurrentOrder] = useState([]);
  const [currentUser, securrentUser] = useState({});
  const dialog = useDialogState();
  const { user } = useAuth0();
  let docRef = {};
  // let fullLeaderBoard = [];
  // const [tempUserDataCheckins, settempUserDataCheckins] = useState([]);
  // let userWalletInfo = {};
  // const nowTime = moment().format("MMM Do YYYY");
  // const currentMonth = moment(new Date()).format('MMMM');

  useEffect(() => {
    const tempProductList = [
      { id: 0, name: 'Cupcake', total: 0, img:'cupcake', category: 'baked-good'},
      { id: 1, name: 'Milkshake', total: 0, img:'cupcake', category: 'drink'},
      { id: 2, name: 'Cookie', total: 0, img:'cupcake', category: 'baked-good'},
      { id: 3, name: 'Cake Jar', total: 0, img:'donut', category: 'baked-good'},
      { id: 3, name: 'Cookie Dough Jar', total: 0, img:'donut', category: 'baked-good'},
      { id: 4, name: 'Extra Points', total: 0, img:'donut', category: 'baked-good'}
    ]
    setProductList(tempProductList);
    // gettingUserInfoFromFullList();
    
    // let tempUserData = []

    const colRef = collection(db, "purchasedTokens")
    //real time update
    onSnapshot(colRef, (snapshot) => {
      let tempTokenHolderArray = [];
      snapshot.docs.forEach((doc) => {
        let totes = 0;
        let item = doc.data();
        item.id = doc.id;
        const formedDate = Date(item.created);

        const emailHolder = item.emailAddress.toLowerCase();
        const userEmail = user.email.toLowerCase();
       
        if (emailHolder === userEmail) {
          console.log('hey here is you', user, ' - ', item);
          // this is who wants to view this page..
          // check if they are admin
          if(item.admin === true) {
            setadminAllowed(true);
          }
        }
        item.userCreated = moment(formedDate).format("MMM YYYY");
        if (item.metaData) {
          totes = item.metaData.totalCheckin;
          // console.log('item.metaData.totalCheckin totes', totes);
          if (totes > 0 && item.status === 'checkedin') {
            tempTokenHolderArray.push(item);
          }
        }
        
      })
      tempTokenHolderArray.sort((a,b) => (a.metaData.totalCheckin < b.metaData.totalCheckin) ? 1 : ((b.metaData.totalCheckin < a.metaData.totalCheckin) ? -1 : 0));

      setLeaderBoard(tempTokenHolderArray);
    })
    
    return () => {};
  }, []);


  const removeFromLobby = async (user) => {
    const userId = user.id;
    docRef = doc(db, "purchasedTokens", userId);
    await updateDoc(docRef, {
      status: 'complete'
      })
    .then((result) => {
      toast.success("SUCCESS: User Removed from lobby!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    });
  }

  const saveUsersPurchase = () => {
    dialog.hide();
  }

  const cancelSave = () => {
    dialog.hide();
  }
  
  const addProdtoOrder = (item) => {
    let builCurrentOrder = currentOrder;
    // builCurrentOrder[item.name] = {};
    let iteming = {
      name: item.name,
      qty: 1
    }
    if (builCurrentOrder[item.name]) {
      // excists
      // add qty
      iteming.qty ++;
      builCurrentOrder[item.name] = iteming;
      // replace shit
    } else {
      builCurrentOrder[item.name] = iteming;
    }
    setcurrentOrder(builCurrentOrder)
  }
  // const selectLobbyUser = (selectedUser) => {
  //   console.log('we got a user', selectedUser);
  //   const getId = selectedUser.metaData.id;
  //   // selectedUser.lastVist = moment(numberGet).format("MMM Do YYYY");
  //   // console.log('numberGet', numberGet);
  //   // securrentUser(selectedUser);
  // }

  const addProduct = (prod) => {
    let tempproductList = productList;
    // loop through product array..
    tempproductList.forEach((doc, i) => {
       // if out a match for prod
      if (doc.name === prod.name) {
        // prod.value = prod.value++;
        let currentTotal = prod.total;
        tempproductList[i].total = currentTotal + 1;
      }
    });
    // fullProductList = tempproductList;
    // console.log('fullProductList ', fullProductList);
    // fullProductList = fullProductList.slice(1);
    setProductList(tempproductList);
  }

  const minusProduct = (prod) => {
    console.log('down', prod);
  }


  return (
    <div className="text-center hero my-5 leadery">
    {/* <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" /> */}
    <h1 className="mb-4">Current Lobby</h1>


    {adminAllowed && 
      <div className="leader-contain">
        {fullLeaderBoard?.map((d, i) => (
         <div key={i+d.id} className="container-lead open-dia diachange">
          <div className="removeuser" onClick={() => removeFromLobby(d)} ><FontAwesomeIcon icon="trash" /></div>
            <Link to={'/lobby-purchase/' + d.metaData.id} >
              {/* <div className={i <= 2 ? 'active-leader-item' : 'leader-item'}> */}
              <div className="leader-item btnlead">
              <Avatar address={d.metaData.email} avatar={d.avatar ? d.avatar : undefined} size="small" />
                {/* <Gravatar email={d.metaData.email} className="gravy" />  */}
                <div className="leader-name">
                  <span className={d.userName ? 'hidden' : 'stuff'}>{d.metaData.nickname.substring(0, 17)}{d.metaData.nickname.length >= 17 && '..'}</span>
                  {d.userName && 
                    <span className="stuff">{d.userName.substring(0, 17)}{d.userName.length >= 17 && '..'}</span>
                  }
                  <div className="leader-level">
                    <span className="level-lev">Level</span>
                    <span>{d.level}</span>
                    {!d.level &&<span>1</span>}
                  </div>
                  <div className="membersince">Member Since: {d.userCreated}</div>
                </div>
                {/* {i === 0 &&
                  <FontAwesomeIcon icon="award" className="gold ico" /> 
                }
                {i === 1 &&
                  <FontAwesomeIcon icon="award" className="silver ico" />
                }
                {i === 2 &&
                  <FontAwesomeIcon icon="award" className="bronze ico" /> 
                } */}
              </div>
            </Link>
          </div>
        ))}
        { fullLeaderBoard?.length < 1 &&
            <p className="padtopst">No users currently checked in!</p>
          }
      </div>
    }
    {!adminAllowed && 
      <p style={{color: "white"}}>Sorry nothin' to see here! <span>(you need admin rights)</span></p>
    }
    <DialogBackdrop {...dialog}  className="backstyle">
      <Dialog className="refix star" {...dialog} aria-label="Welcome">
        <h3 className="claimh">{ currentUser?.metaData?.name }</h3>
        <span className="cuseremail">{ currentUser?.emailAddress }</span>
        <Button className="closebtn" onClick={dialog.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
        <div className="purchase-container">
          <div className="userinfo">
            <span className="cuserlevel"><strong>Total Checkins:</strong> { currentUser?.metaData?.totalCheckin }</span>
            <span className="lastvis"><strong>Last Visit:</strong> { currentUser?.lastVist }</span>
          </div>
          <div className="porductinfod">
            {productList?.map((j, i) => (
              <div key={i} className="product-i">
                <Button className="inc down" onClick={() => minusProduct(j)}>
                  <FontAwesomeIcon icon="minus" className="mr-2" />
                  </Button>
                <h4 className="prodname">
                  {j.name}
                  <span className="totals">{j.total}</span>  
                </h4>
                <Button className="inc up"  onClick={() => addProduct(j)}>
                  <FontAwesomeIcon icon="plus" className="mr-2" />
                  </Button>
              </div>
            ))}
            
          </div>
          {/* <div className="orderfill">
            <strong>Total Order Info</strong>
            {currentOrder?.map((k, i) => (
              <div key={i} className="product-i">
                <h4 className="prodname">{k.name}</h4>
                <h4 className="prodname">{k.qty}</h4>
              </div>
            ))}
          </div> */}
          <div className="button-holders">
            <Button className="clicklink linker greygoose" onClick={() => cancelSave()}>CANCEL</Button>
            <Button className="clicklink linker" onClick={() => saveUsersPurchase()}>COMPLETE SALE</Button>
          </div>
        </div>
      </Dialog>
      </DialogBackdrop>
      <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
              {/* Same as */}
            <ToastContainer />
    
  </div>
  
  );
};


export default withAuthenticationRequired(Lobby, {
  onRedirecting: () => <Lobby />,
});
