import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from 'moment';
// import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { ToastContainer, toast } from 'react-toastify';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { db } from '../firebase';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import { getDoc, doc, updateDoc,getDocs, collection } from "firebase/firestore";
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";
import { Button } from "reakit/Button";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import styled from "styled-components";
import ProgressBar from "@ramonak/react-progress-bar";
// import rewards from '../rewards.json';
import { environment } from '../environments';
import Konami from "konami-code-js";
import { AvatarContext } from "../AvatarProvider";
import Avatar from '../utils/avatar';
import NameGenerator from '../components/Namegenerator';

export const dialogStyles = styled.div`
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(-50%, -10%, 0) rotateX(90deg);
  &[data-enter] {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const ProfileComponent = () => {
  const InitUserMetaData = {
    userName: '',
    avatar: 'Gravatar',
    bio: '',
    email: '',
    twitter: ''
}

const InitValue = {
  checkins: [],
  address: '',
  created: '',
  membersince: '',
  lastcheckin: '',
  totalPoints: 0,
  level: 0,
  rewards: [],
  tests: []
}
const { avatarList } = useContext(AvatarContext);
  const [currentSelectedUser, setcurrentSelectedUser] = useState(InitValue);
  const dialog = useDialogState();
  const dialogBadge = useDialogState();
  const dialogPrize = useDialogState();
  const dialogKonami = useDialogState();
  const [hideShit, sethideShit] = useState(false);
  const [isWalletCOnnected, setisWalletCOnnected] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const { user } = useAuth0();
  const [currentBadgeInfo, setcurrentBadgeInfo] = useState({});
  const [currentPrizeo, setcurrentPrizeo] = useState({});
  const [userWalletInfo, setuserWalletInfo] = useState({});
  const [tempUserDataCheckins, settempUserDataCheckins] = useState([]);
  const [originCheckins, setoriginCheckins] = useState([]);
  const [tempUserRewards, settempUserRewards] = useState([]);
  const [tempUserBonusRewards, settempUserBonusRewards] = useState([]);
  const [maxLevel, setmaxLevel] = useState(0);
  const [tempHolderBonusRewards, settempHolderBonusRewards] = useState([]);
  const [prizes, setPrizes] = useState([]);
  const [ userMetaData, setUserMetaData] = useState(InitUserMetaData);
  const [ userMetaDataOriginal, setUserMetaDataOriginal] = useState(InitUserMetaData);
  const [levelRewardsData, setLevelRewardsData] = useState([]); // Store all level rewards
  const [newUserName, setNewUserName] = useState(
    {
      userName: '',
      walletAddress: ''
    }
  );
  const [userAddress, setUserAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
  });
  
  
  let docRef = {};

  useEffect(()=>{
    new Konami(async function () {
        // This part will be executed if « Up Up Down Down Left Right Left Right B A »
        // show konami easter egg
        dialogKonami.show()
        // deep clone users unlocked avatar codes
        let clonedEasterEggs = userMetaData?.easterEggs !== undefined ? JSON.parse(JSON.stringify(userMetaData.easterEggs)) : [];
        // add value to clone adn send value to DB for konami
        
        const containersAlready = clonedEasterEggs.includes('konami');
        if (containersAlready === false) {
          clonedEasterEggs.push('konami');
          docRef = doc(db, "purchasedTokens", user.nickname);
          await updateDoc(docRef, {
            easterEggs: clonedEasterEggs
           })
          .then((result) => {
            setuserWalletInfo(values => ({...values, "easterEggs": clonedEasterEggs}))
            dialog.hide();
            toast.success("SUCCESS: Earned new avatar!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          });
          
        } else {
          console.log('user has reward');
        }
    });
  },[])

  useEffect(() => {
    fetchLevelRewards();
    // getFollowers();
  }, []);

  useEffect(() => {
    if (levelRewardsData.length > 0) {
      gettingUserInfoFromFullList();
    }
  }, [levelRewardsData]);

  const matchedRewards = tempUserRewards.map((earnedReward) => {
    return levelRewardsData.find((reward) => reward.level === earnedReward.level) || {};
  });


  
  function isFirestoreTimestamp(obj) {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      obj.constructor.name === 'ut' &&
      'seconds' in obj &&
      typeof obj.seconds === 'number' &&
      'nanoseconds' in obj &&
      typeof obj.nanoseconds === 'number'
    );
  }

  class FirebaseTimestamp {
    constructor(seconds, nanoseconds) {
      this.seconds = seconds;
      this.nanoseconds = nanoseconds;
    }
  
    toDate() {
      return new Date(this.seconds * 1000 + this.nanoseconds / 1000000);
    }
  }

  const fetchLevelRewards = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "levelRewards"));
      const rewards = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLevelRewardsData(rewards);
    } catch (error) {
      console.error("Error fetching level rewards:", error);
    }
  };

  
  const handleNameChange = (newName) => {
    // Do something with the new name, such as updating state\
    setUserMetaData(values => ({...values, userName: newName}))
  };
  
  function convertToUTFormat(date) {
    return new FirebaseTimestamp(date.seconds, date.nanoseconds);
  }

  const saveUser = async () => {
    let clonedUser = JSON.parse(JSON.stringify(userMetaData));
    if(isFirestoreTimestamp(userMetaData.created)) {
      clonedUser.created = userMetaData.created.toDate();
    } else {
      const newSteveDate  = convertToUTFormat(userMetaData.created);
      clonedUser.created = newSteveDate.toDate();
    }
    // clonedUser.created = moment(userMetaData.created).format("MMM YYYY");

    clonedUser.metaData.checkins = originCheckins;
    // clonedUser.metaData = userMetaData.metaData;

    let walletstuff = JSON.parse(JSON.stringify(userWalletInfo));
    walletstuff.avatar = clonedUser.avatar;
    walletstuff.userName = clonedUser.userName;
    setuserWalletInfo(walletstuff);
    updateDoc(doc(db, "purchasedTokens", user.nickname), 
      clonedUser
      )
      .then((result) => {
        setcurrentSelectedUser(clonedUser);
        setNewUserName(values => ({...values, "userName": clonedUser.userName}))
        // setNewUserName(values => ({...values, "twitter": clonedUser.twitter}))
        // handle user update status
      });
      dialog.hide();
}

// this is the ID for @TwitterDev
const userId = 2244994945;
const url = `https://api.twitter.com/2/users/${userId}/followers`;
const bearerToken = environment.BEARER;

const isDisabled = (e) => {
  // console.log('disabled ', e);
  if (e.criteria > userWalletInfo?.totalPoints) {
    return true;
  } else {
    if(e.date !== undefined) {
      if (ifHasDate(e.date, userMetaData?.metaData?.checkins)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }
}

  const truncateWallet = (add) => {
    if (add === undefined) {
      return;
    }
    return add.substring(0, 13) + "..." + add.substring(36, 42);
  }

  const saveUserName = async () => {
    const formingWallet = userWalletInfo;
    docRef = doc(db, "purchasedTokens", user.nickname);
    await updateDoc(docRef, {
      userName: newUserName.userName !== '' ? newUserName.userName : formingWallet.userName,
      // walletAddress: newUserName.walletAddress !== '' ? newUserName.walletAddress : formingWallet.walletAddress
    })
    .then((result) => {
      dialog.hide();
      toast.success("SUCCESS: Updated username successfully!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      formingWallet.userName = newUserName.userName;
      setuserWalletInfo(formingWallet);
      sethideShit(true);
    });
  }
  
  const cancelSave = async () => {
    dialog.hide();
    const name = 'userName';
    const value = '';
    setNewUserName(values => ({...values, [name]: value}))
  }

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };
  
  const gettingUserInfoFromFullList = async () => {
    let tempUserData = []
    docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let tempUserDataCheckins = [];
      tempUserData = docSnap.data();
      setPrizes(tempUserData.prizesClaimed)
       // USER HAS A TOKEN
        // console.log('user ', tempUserData);
        if(tempUserData.metaData) {
          const formedDate = Date(tempUserData.created);
          // hawk
          let clonedDates = JSON.parse(JSON.stringify(tempUserData.metaData.checkins));
          setoriginCheckins(clonedDates);
          let tempUserCheckins = tempUserData.metaData.checkins;
          tempUserCheckins.userName = tempUserData.userName;
          tempUserData.metaData.checkins.forEach((date, i) => {
            tempUserCheckins[i] = moment(date).format("MMM Do YYYY");
          });
          if(tempUserData.rewards) {
            tempUserData.rewards.sort((a,b) => (a.rid < b.rid) ? 1 : ((b.rid < a.rid) ? -1 : 0));
            settempUserRewards(tempUserData.rewards);
          }
          if(tempUserData.bonusrewards) {
            tempUserData.bonusrewards.sort((a,b) => (a.rid < b.rid) ? 1 : ((b.rid < a.rid) ? -1 : 0));
            settempUserBonusRewards(tempUserData.bonusrewards);
          }
          user.created = moment(formedDate).format("MMM YYYY");
          user.lastVisit = moment().format('L');
          settempUserDataCheckins(tempUserCheckins);
        }
        if(tempUserData.userName) {
          sethideShit(true);
        }
        // console.log(holder, 'holder')
        if(tempUserData.walletAddress) {
          // console.log('it has a wallet')
          setisWalletCOnnected(true);
        }
        setuserWalletInfo(tempUserData);
        
        levelMax(tempUserData.level);
        // tempUserData
        user.id = user.nickname;
        user.totalCheckin = tempUserDataCheckins.length;

    }


   
        
  };

  const handleClaimPrize = (prize) => {
    if (prize.claimed) {
      return;
    }
    setcurrentPrizeo(prize);
    setShowAddressForm(true);
  
    // Pre-fill the address if it exists
    if (userWalletInfo?.address) {
      setUserAddress({
        street: userWalletInfo.address.street || "",
        city: userWalletInfo.address.city || "",
        state: userWalletInfo.address.state || "",
        zip: userWalletInfo.address.zip || "",
      });
    }
  };
  
  
  const saveAddressAndClaim = async (e) => {
    e.preventDefault();
    try {
      const updatedPrizes = prizes.map((p) =>
        p.option === currentPrizeo.option ? { ...p, claimed: true } : p
      );
  
      // Update Firebase
      const docRef = doc(db, "purchasedTokens", user.nickname);
      await updateDoc(docRef, {
        prizesClaimed: updatedPrizes,
        address: userAddress,
      });
  
      // Send notification email
      await sendClaimNotificationEmail(currentPrizeo, userAddress);
  
      // Update state
      setPrizes(updatedPrizes);
      setShowAddressForm(false);
      setUserAddress({ street: "", city: "", state: "", zip: "" });
  
      toast.success("Prize claimed successfully!");
    } catch (error) {
      console.error("Error claiming prize:", error);
    }
  };
  
  const sendClaimNotificationEmail = async (prize, address) => {
    try {
      const docRef = doc(db, "purchasedTokens", user.nickname);
  
      // Update the Firestore document to indicate the prize has been claimed
      const updatedPrizes = prizes.map((p) =>
        p.option === prize.option ? { ...p, claimed: true } : p
      );
  
      await updateDoc(docRef, {
        prizesClaimed: updatedPrizes,
        address: {
          street: address.street || "",
          city: address.city || "",
          state: address.state || "",
          zip: address.zip || "",
        },
      });
  
      toast.success("Prize claim has been submitted!");
    } catch (error) {
      console.error("Error updating prize claim:", error);
      toast.error("Failed to submit prize claim. Please try again.");
    }
  };
  
  

  const ifHasDate = (rewardDate, checkins) => {
    // const tempVar = checkins.find(date =>  moment(date).format("MM/DD/YYYY") == moment(rewardDate).format("MM/DD/YYYY"));
    const tempVar2 = checkins?.find(item => item == moment(rewardDate).format("MMM Do YYYY"));

    if (tempVar2 !== undefined) return true;
  }
  const levelMax = (level) => {
    levelRewardsData.forEach((reward) => {
      if (reward.level === (level + 1)) {
        setmaxLevel(reward.criteria);
      }
      // setting first level if its missing for some reason
      if (level == null) {
        const push = {name: 'First Checkin Reward!', reward: 'NFD Rewards Membership', image:'cherry', rid: 0};
        setmaxLevel(levelRewardsData[1].criteria);
        settempUserRewards([push]);
        
      }
    });
  }

  const badgeDetails = (badge) => {
    setcurrentBadgeInfo(badge);
  }
  const prizeDetails = (prize) => {
    setcurrentPrizeo(prize);
  }

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserMetaData(values => ({...values, [name]: value}))
  }

  return (
    <Container className="mb-5 overwrite">
      {/* <h1 className="mb-4">Profile</h1> */}
      <div className="leader-contain starty">
        <div className="user-inf-new">
         <Avatar address={user.email} avatar={userWalletInfo?.avatar} />
         {userWalletInfo.userName && 
              <h1 className={newUserName.userName && 'hidden'}>{ userWalletInfo.userName }</h1>
            }
            {newUserName && 
              <h1>{ newUserName.userName }</h1>
            }
            {!newUserName.userName && 
             <h1 className={hideShit ? 'hidden' : ''}>{user.nickname}</h1>
            }
            <p className="whitewallet">{ truncateWallet(userWalletInfo?.walletAddress)}</p>
            <DialogDisclosure className="open-dia" {...dialog} onClick={() => setUserMetaData(userWalletInfo)}><p className="edit-button">Edit Profile <FontAwesomeIcon icon="edit" className="editbut" /></p></DialogDisclosure>
            <p className="p-tag minus-bot">Member Since <br /><strong>{user.created}</strong></p>
            <p className="p-tag minus-bot plus">Last Visit<br /><strong>{ user.lastVisit}</strong></p>
            <p className="pointpro">{userWalletInfo.totalPoints}<br /><span className="lab">total points</span></p>
            <div className="stat-holders">
              <p className="p-tag">Checkins <br /> <strong>{tempUserDataCheckins.length}</strong></p>
              {/* <p className="p-tag bordera">Purchases  <br /><strong>{userWalletInfo.totalPoints -tempUserRewards.length}</strong></p> */}
              <p className="p-tag bordera">Level <br /> <strong>{userWalletInfo.level ? userWalletInfo.level : 1}</strong></p>
              <p className="p-tag">Rewards <br /><strong>{tempUserRewards.length + tempHolderBonusRewards.length}</strong></p>
            </div>
            <ProgressBar
              className="progress-bar-hawk"
              bgColor="#1bbf25"
              completed={userWalletInfo?.totalPoints || 0} // Default to 0 if undefined
              customLabel=" " // To avoid rendering any unwanted labels
              maxCompleted={maxLevel || 100} // Default maxLevel to 100 if undefined
            />
            <p className="progress-line">
              {maxLevel 
                ? `${maxLevel - (userWalletInfo?.totalPoints || 0)} more points needed for Level ${
                    userWalletInfo?.level ? userWalletInfo.level + 1 : 2
                  }`
                : "Calculating next level... ("+ maxLevel+ " points)"}
            </p>
          
          
          <div className="badge-container">
            <span className="badge-label">earned badges:</span>
            <div className="badges bwu-contatiner">
            {matchedRewards.map((reward, index) => (
              <div className="badge bwu" key={index}>
                {reward.image && (
                  <DialogDisclosure
                    className="badge-dialog"
                    {...dialogBadge}
                    onClick={() => badgeDetails(reward)}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${reward.image})`,
                        backgroundSize: "cover",
                        width: "90px",
                        height: "90px",
                      }}
                      className="leader-rank newbadge"
                    ></div>
                  </DialogDisclosure>
                )}
              </div>
            ))}           
             
            </div>
   
          </div>
            {prizes?.length > 0 &&
              <div className="badge-container">
            <span className="badge-label">earned prizes:</span>
            <div className="badges bwu-contatiner">
            {prizes?.map((prize, index) => (
              <div className="badge bwu" key={prize.option+index}>
                
                  <DialogDisclosure
                    className="badge-dialog"
                    {...dialogPrize}
                    onClick={() => prizeDetails(prize)}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${prize.image ? prize.image : "https://firebasestorage.googleapis.com/v0/b/lpm-rewards.appspot.com/o/DALL%C2%B7E%202024-12-17%2019.58.31%20-%20A%202D%20pixel%20art%20style%20image%20of%20a%20generic%20prize%20or%20present.%20The%20image%20features%20a%20small%2C%20brightly%20wrapped%20gift%20box%20with%20a%20ribbon%2C%20designed%20in%20a%20simplisti.webp?alt=media&token=78b4430f-6ed1-492b-b8dd-eaafef11eb52https://firebasestorage.googleapis.com/v0/b/lpm-rewards.appspot.com/o/DALL%C2%B7E%202024-12-17%2019.58.31%20-%20A%202D%20pixel%20art%20style%20image%20of%20a%20generic%20prize%20or%20present.%20The%20image%20features%20a%20small%2C%20brightly%20wrapped%20gift%20box%20with%20a%20ribbon%2C%20designed%20in%20a%20simplisti.webp?alt=media&token=78b4430f-6ed1-492b-b8dd-eaafef11eb52"})`,
                        backgroundSize: "cover",
                        width: "90px",
                        height: "90px",
                        paddingBottom: "4px"
                      }}
                      className="leader-rank newbadge"
                    ></div>
                  </DialogDisclosure>
             
              </div>
            ))}             
            </div>
   
          </div>
          }
        </div>
      </div>


      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        
       
        <Col md={4}>

        </Col>
      </Row>


      <hr/>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
      <ToastContainer />
      <DialogBackdrop {...dialog}  className="backstyle">
            <Dialog className="refix" {...dialog} aria-label="Welcome">
              <h3 className="claimh">Modify Profile</h3>
              <Button className="closebtn" onClick={dialog.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
              <div className="flutarsky">
              

<Form>
                {/* <FormGroup>
                    <Label for="userName">User Name:</Label>
                    <Input type="text" name="userName" id="userName" 
                       value={userMetaData.userName || ""}
                        onChange={(e) => {handleChangeForm(e)}}
                        placeholder="Username"
                    />
                </FormGroup> */}
        {userWalletInfo.userName &&
          <NameGenerator
            originalName={userWalletInfo.userName || newUserName?.userName}
            display={true}
            regenerateButton={true}
            onNameChange={handleNameChange}
        />
        }
                <FormGroup tag="fieldset">
                  {/* <h4 className="avatartitle">Avatar:</h4> */}
                    <div className="avatar-selector">
                    <div className="avatar-list">
  {avatarList?.map((e, j) => {
    // Determine if this avatar should be shown based on criteria
    const showCriteria1 = e.criteria === 1;
    const showCriteria2 = e.criteria > 1 && userWalletInfo?.totalPoints > e.criteria;
    const showHasDate = e.date !== undefined && ifHasDate(e.date, userMetaData?.metaData?.checkins);

    // Only return a <div> if any criteria is met
    if (showCriteria1 || showCriteria2 || showHasDate) {
      return (
        <div key={j + e.type} className="item-a">
          {/* Render based on criteria */}
          {showCriteria1 && (
            <FormGroup check key={j + e.type}>
              <Label check>
                <Input
                  name="avatar"
                  disabled={isDisabled(e)}
                  type="radio"
                  value={e.type}
                  checked={userMetaData.avatar === e.type}
                  onChange={(e) => handleChangeForm(e)}
                />{' '}
                <Avatar address={user.email} avatar={e.type} active={userMetaData.avatar === e.type} />
                <span>{e.name}</span>
              </Label>
            </FormGroup>
          )}
          {showCriteria2 && (
            <FormGroup check key={j + e.type}>
              <Label check>
                <Input
                  name="avatar"
                  disabled={isDisabled(e)}
                  type="radio"
                  value={e.type}
                  checked={userMetaData.avatar === e.type}
                  onChange={(e) => handleChangeForm(e)}
                />{' '}
                <Avatar address={user.email} avatar={e.type} />
                <span>{e.name}</span>
              </Label>
            </FormGroup>
          )}
          {showHasDate && (
            <div className="item-a">
            <FormGroup check key={j + e.type}>
              <Label check>
                <Input
                  name="avatar"
                  disabled={isDisabled(e)}
                  type="radio"
                  value={e.type}
                  checked={userMetaData.avatar === e.type}
                  onChange={(e) => handleChangeForm(e)}
                />{' '}
                <Avatar address={user.email} avatar={e.type} />
                <span>{e.type}</span>
              </Label>
            </FormGroup>
            </div>
          )}
        </div>
      );
    }
    // If no criteria is met, return null (no element is rendered)
    return null;
  })}
  {userMetaData?.easterEggs?.includes('konami') && (
    <div className="item-a">
    <FormGroup check>
      <Label check>
        <Input
          name="avatar"
          disabled={false}
          type="radio"
          value={'konami'}
          checked={userMetaData.avatar === 'konami'}
          onChange={(e) => handleChangeForm(e)}
        />{' '}
        <Avatar address={user.email} avatar="konami" />
        <span>Konami</span>
      </Label>
    </FormGroup>
    </div>
  )}
</div>

                        {/* <div className="avatar-image-container">
                            
                            <span className="preview-image">PREVIEW:</span>
                        </div> */}
                    </div>
                
                </FormGroup>
            </Form>
                <div className="avatar-saver">
                  <Button className="clicklink linker" style={{marginTop: '0px'}} disabled={userMetaData.userName === ''} onClick={() => saveUser()}>SAVE</Button>
                  <Button className="clicklink linker greygoose" style={{marginTop: '0px'}} onClick={() => cancelSave()}>CANCEL</Button>
                </div>
              </div>
            
            </Dialog>
            </DialogBackdrop>


            <DialogBackdrop {...dialogBadge}  className="backstyle">
            <Dialog className="refix" {...dialogBadge} aria-label="Welcome">
              <h3 className="claimh claimg smtitle">Badge Details</h3>
              <Button className="closebtn" onClick={dialogBadge.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
              <div className="flutarsky">
               <div className="childtarsky">
                  <div className="imgholdy zero-top">
                    <div 
                      style={{
                        backgroundImage: `url(${currentBadgeInfo.image})`,
                        backgroundSize: 'cover', // Ensures the image covers the div
                        backgroundPosition: 'center', // Centers the image
                        backgroundRepeat: 'no-repeat' // Prevents repeating
                      }}
                      className={'leader-rank newbadge newbig'}></div>
                  </div>
                  { currentBadgeInfo.level > 0 && 
                  <div className="holdertarsky modslvl">
                    <span >Level {currentBadgeInfo.level} Reward</span>
                  </div>
                  }
                  <div className="holdertarsky bigtitle">
                    {/* <span className="lab">Reward Name:</span> */}
                    {currentBadgeInfo.name}
                    <p className="rewards-description">{currentBadgeInfo.description}</p>
                  </div>                 
                
                  <div className="holdertarsky">
                    <span className="lab">Reward Earned:</span>
                    {currentBadgeInfo.reward}
                  </div>

                  {currentBadgeInfo.pointTotal &&
                    <div className="holdertarsky">
                      <span className="lab">Total Points Earned:</span>
                      {currentBadgeInfo.pointTotal}
                    </div>
                  }
                  
                
                  {currentBadgeInfo.claimed && currentBadgeInfo.type === 'physical' &&
                    <div className="status-rew claimed">CLAIMED</div> 
                  }
                  {/* <div className="status-rew expired">EXPIRED</div> */}
                  {!currentBadgeInfo.claimed && currentBadgeInfo.type === 'physical' &&
                      <div className="status-rew activer">REDEEMABLE</div>
                      
                  }

                  <TwitterShareButton className="social-share zeropadtop" hashtags={['lucypackmagic', 'loyaltyrewards']} url="https://rewards.lucypackmagic.com/" title={'I just earned the "' + currentBadgeInfo.name + '" reward! '}>
                    <p className="clicklink linker close-numy share-twitty">Share on Twitter <TwitterIcon className="twitty" size={30} round={true} /></p>   
                  </TwitterShareButton>
               </div>
              </div>
            
            </Dialog>
            </DialogBackdrop>

            <DialogBackdrop {...dialogPrize} className="backstyle">
              <Dialog className="refix" {...dialogPrize} aria-label="Prize Details">
                <h3 className="claimh claimg smtitle">Prize Details</h3>
                <Button className="closebtn" onClick={dialogPrize.hide}>
                  <FontAwesomeIcon icon="times" className="mr-2" />
                </Button>
                <div className="flutarsky">
                  <div className="childtarsky">
                    <div className="imgholdy zero-top">
                      <div
                        style={{
                          backgroundImage: `url(${currentPrizeo.image || "https://firebasestorage.googleapis.com/v0/b/lpm-rewards.appspot.com/o/DALL%C2%B7E%202024-12-17%2019.58.31%20-%20A%202D%20pixel%20art%20style%20image%20of%20a%20generic%20prize%20or%20present.%20The%20image%20features%20a%20small%2C%20brightly%20wrapped%20gift%20box%20with%20a%20ribbon%2C%20designed%20in%20a%20simplisti.webp?alt=media&token=78b4430f-6ed1-492b-b8dd-eaafef11eb52https://firebasestorage.googleapis.com/v0/b/lpm-rewards.appspot.com/o/DALL%C2%B7E%202024-12-17%2019.58.31%20-%20A%202D%20pixel%20art%20style%20image%20of%20a%20generic%20prize%20or%20present.%20The%20image%20features%20a%20small%2C%20brightly%20wrapped%20gift%20box%20with%20a%20ribbon%2C%20designed%20in%20a%20simplisti.webp?alt=media&token=78b4430f-6ed1-492b-b8dd-eaafef11eb52"})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          width: "100px",
                          height: "100px",
                        }}
                        className="leader-rank newbadge newbig"
                      ></div>
                    </div>
                    <div className="holdertarsky bigtitle">
                      {currentPrizeo.option}
                      <p className="rewards-description">{currentPrizeo?.description}</p>
                    </div>
                    <div className="holdertarsky">
                      <span className="lab">Date Earned:</span>
                      {formatDate(currentPrizeo?.create_date)}
                    </div>
                    <div className="status-rew activer" style={{cursor: "pointer"}} onClick={() => handleClaimPrize(currentPrizeo)}>
                      {currentPrizeo.claimed ? "CLAIMED" : "REDEEMABLE"}
                      
                    </div>
                    {currentPrizeo.claimed === "REDEEMABLE" && <span className="lab" style={{fontSize: "14px", fontStyle: "italic"}} onClick={() => handleClaimPrize(currentPrizeo)}>Click here to redeem this prize!</span> }
                    {showAddressForm && userAddress && (
                    <form onSubmit={saveAddressAndClaim} style={{ marginTop: "20px" }}>
                      <h3 className="header-pcl">Confirm Address:</h3>
                      <div style={{ marginBottom: "15px", textAlign: "left" }}>
                        <label style={{ display: "block", fontWeight: "bold" }}>Street:</label>
                        <input
                          type="text"
                          value={userAddress.street || ""}
                          onChange={(e) => {
                            e.persist(); // Ensure the event remains accessible
                            setUserAddress((prev) => ({ ...prev, street: e.target.value || "" }));
                          }}
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            marginTop: "5px",
                             textAlign: "left"
                          }}
                          required
                        />



                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <div style={{ flex: 1, textAlign: "left" }}>
                          <label style={{ display: "block", fontWeight: "bold" }}>City:</label>
                          <input
                            type="text"
                            value={userAddress?.city}
                            onChange={(e) =>
                              setUserAddress((prev) => ({ ...prev, city: e?.target?.value }))
                            }
                            style={{
                              width: "100%",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              marginTop: "5px",
                              
                            }}
                            required
                          />
                        </div>
                        <div style={{ width: "80px",  textAlign: "left" }}>
                          <label style={{ display: "block", fontWeight: "bold" }}>State:</label>
                          <input
                            type="text"
                            value={userAddress.state}
                            onChange={(e) =>
                              setUserAddress((prev) => ({ ...prev, state: e?.target?.value }))
                            }
                            style={{
                              width: "100%",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              marginTop: "5px",
                            }}
                            required
                          />
                        </div>
                        <div style={{ width: "100px",  textAlign: "left" }}>
                          <label style={{ display: "block", fontWeight: "bold" }}>Zip:</label>
                          <input
                            type="text"
                            value={userAddress.zip}
                            onChange={(e) =>
                              setUserAddress((prev) => ({ ...prev, zip: e?.target?.value }))
                            }
                            style={{
                              width: "100%",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              marginTop: "5px",
                            }}
                            required
                          />
                        </div>
                      </div>
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#4CAF50",
                          color: "#fff",
                          border: "none",
                          padding: "10px 20px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        Submit and Claim
                      </Button>
                    </form>
                  )}

                  </div>
                </div>
              </Dialog>
            </DialogBackdrop>


            <DialogBackdrop {...dialogKonami}  className="backstyle">
            <Dialog className="refix konegg" {...dialogKonami} aria-label="Welcome">
              {/* <h3 className="claimh claimg">Badge Details</h3> */}
              {/* <Button className="closebtn" onClick={dialogKonami.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button> */}
              <div className="flutarsky">
                <h1 className="egg-header">Achievement Unlocked</h1>
                <span className="sub-egg-header"><strong>Player 1: </strong> You have proved yourself a worthy opponent. For that you have been granted a new avatar.</span>
                <div className="childtarsky eggbgd">
                  
                </div>
                <div className="eggclose" onClick={dialogKonami.hide}><FontAwesomeIcon icon="times" className="eggclosebtn" /> Close</div>
              </div>
            
            </Dialog>
            </DialogBackdrop>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
