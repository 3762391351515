import React, { useState, useEffect } from "react";
import { Container, Input, Button } from "reactstrap";
import { Dialog, DialogBackdrop, useDialogState } from "reakit/Dialog";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RewardAdmin = () => {
  const [rewards, setRewards] = useState([]);
  const [newReward, setNewReward] = useState({
    name: "",
    description: "",
    cost: 0,
    route: "",
    image: null, // Image object
  });
  const [selectedReward, setSelectedReward] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const dialogState = useDialogState();

  useEffect(() => {
    fetchRewards();
  }, []);

  const fetchRewards = async () => {
    try {
      const rewardList = [];
      const snapshot = await getDocs(collection(db, "rewards"));
      snapshot.forEach((doc) => {
        const data = doc.data();
        rewardList.push({ ...data, id: doc.id });
      });
      setRewards(rewardList);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  };

  const handleImageUpload = async () => {
    if (!imageFile) return null;

    try {
      const storageRef = ref(storage, `rewards/${imageFile.name}`);
      const uploadTaskSnapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSaveReward = async (e) => {
    e.preventDefault();
    try {
      const imageUrl = await handleImageUpload();

      // Build the image object
      const imageObject = imageUrl
        ? {
            uri: imageUrl,
            offsetX: 0,
            offsetY: 0,
            sizeMultiplier: 1,
            landscape: false,
          }
        : null;

      const rewardData = {
        ...newReward,
        image: imageObject,
      };

      if (selectedReward) {
        const docRef = doc(db, "rewards", selectedReward.id);
        await updateDoc(docRef, rewardData);
      } else {
        await addDoc(collection(db, "rewards"), rewardData);
      }

      setNewReward({
        name: "",
        description: "",
        cost: 0,
        route: "",
        image: null,
      });
      setSelectedReward(null);
      setImageFile(null);
      fetchRewards();
      dialogState.hide();
    } catch (error) {
      console.error("Error saving reward:", error);
    }
  };

  const handleEditReward = (reward) => {
    setSelectedReward(reward);
    setNewReward(reward);
    dialogState.show();
  };

  const handleDeleteReward = async (id) => {
    try {
      const docRef = doc(db, "rewards", id);
      await deleteDoc(docRef);
      fetchRewards();
    } catch (error) {
      console.error("Error deleting reward:", error);
    }
  };

  const closeModal = () => {
    setNewReward({
      name: "",
      description: "",
      cost: 0,
      route: "",
      image: null,
    });
    setSelectedReward(null);
    setImageFile(null);
    dialogState.hide();
  };

  return (
    <Container>
      <Button onClick={dialogState.show}>Add Reward</Button>
      <div className="bonusholder">
        <div className="bonus-reward">
          <div className="table-title-th"><strong>Reward Name</strong></div>
          <div className="table-title-th"><strong>Description</strong></div>
          <div className="table-title-th"><strong>Points Cost</strong></div>
          <div className="table-title-th"><strong>Route</strong></div>
          <div className="table-title-th"><strong>Image</strong></div>
          <div className="table-title-th"><strong>Actions</strong></div>
        </div>
        {rewards.map((reward) => (
          <div className="bonus-reward" key={reward.id}>
            <div>{reward.name}</div>
            <div>{reward.description}</div>
            <div>{reward.cost}</div>
            <div>{reward.route}</div>
            <div>
              {reward.image?.uri ? (
                <img
                  src={reward.image.uri}
                  alt={reward.name}
                  style={{ width: "50px", height: "50px" }}
                />
              ) : (
                "No Image"
              )}
            </div>
            <div>
              <span className="rightbtnpad" onClick={() => handleEditReward(reward)}>
                <FontAwesomeIcon icon="edit" className="mr-3" />
              </span>
              <span className="mr-3 highz" onClick={() => handleDeleteReward(reward.id)}>
                <FontAwesomeIcon icon="trash" className="mr-3" />
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <DialogBackdrop {...dialogState} className="backstyle">
        <Dialog className="refix" {...dialogState} aria-label="Reward Modal">
          <h3>{selectedReward ? "Update Reward" : "Add New Reward"}</h3>
          <Button className="closebtn" onClick={closeModal}>Close</Button>
          <form onSubmit={handleSaveReward}>
            <label className="full-width">
              Reward Name:
              <Input
                type="text"
                placeholder="Reward Name"
                value={newReward.name}
                onChange={(e) => setNewReward({ ...newReward, name: e.target.value })}
              />
            </label>
            <label className="full-width">
              Description:
              <Input
                type="text"
                placeholder="Reward Description"
                value={newReward.description}
                onChange={(e) => setNewReward({ ...newReward, description: e.target.value })}
              />
            </label>
            <label className="full-width">
              Points Cost:
              <Input
                type="number"
                placeholder="Points Cost"
                value={newReward.cost}
                onChange={(e) => setNewReward({ ...newReward, cost: Number(e.target.value) })}
              />
            </label>
            <label className="full-width">
              Route:
              <Input
                type="text"
                placeholder="Route (e.g., /spin)"
                value={newReward.route}
                onChange={(e) => setNewReward({ ...newReward, route: e.target.value })}
              />
            </label>
            <label className="full-width">
              Image:
              <Input
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </label>
            <div className="avatar-saver padtoplg">
              <Button className="clicklink linker" type="submit">
                {selectedReward ? "Update" : "Save"}
              </Button>
              <Button className="clicklink linker greygoose" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </form>
        </Dialog>
      </DialogBackdrop>
    </Container>
  );
};

export default RewardAdmin;
