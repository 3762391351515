import '../SpinningWheel.css';
import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getDoc, getDocs, collection, doc, updateDoc } from "firebase/firestore";
import Loading from "../components/Loading";
import { db } from '../firebase';
import { Wheel } from "react-custom-roulette";
import spinon from "../assets/spin-on.png";
import spinfin from "../assets/spin-win.png";
import spinoff from "../assets/spin-off.png"; 
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

const PrizeWheel = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [currentUserforInfo, setCurrentUserforInfo] = useState(undefined);
  const [data, setData] = useState([]); // Dynamic prize data
  const { width, height } = useWindowSize();
  const { user } = useAuth0();

  const SPIN_COST = 20;

  useEffect(() => {
    getUserStuff();
    fetchPrizes();
  }, []);

  // Fetch user information from Firestore
  const getUserStuff = async () => {
    const docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const tempUserData = docSnap.data();
      setCurrentUserforInfo(tempUserData);
    }
  };

  // Fetch prizes from Firestore
  const fetchPrizes = async () => {
    try {
      const prizeList = [];
      const snapshot = await getDocs(collection(db, "prizes"));
      snapshot.forEach((doc) => {
        const data = doc.data();
        // Map Firestore document to prize format expected by the Wheel component
        prizeList.push({
          option: data.option,
          style: {
            backgroundColor: data.style?.backgroundColor || "#ffffff",
            textColor: data.style?.textColor || "#000000",
          },
          image: data.image || null, // Optional image for the prize
        });
      });
      setData(prizeList);
    } catch (error) {
      console.error("Error fetching prizes:", error);
    }
  };

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const updatePointsAndPrizes = async () => {
    if (!currentUserforInfo) return;
  
    // Include the full prize object and the create_date timestamp with claimed: false
    const currentPrize = {
      ...data[prizeNumber],
      create_date: new Date().toISOString(), // Add current date in ISO format
      claimed: false, // Add claimed property
    };
  
    // Update claimed points
    const updatedClaimedPoints = (currentUserforInfo.claimedPoints || 0) + SPIN_COST;
  
    // Update prizesClaimed with the full prize object
    const updatedPrizes = [
      ...(currentUserforInfo.prizesClaimed || []),
      currentPrize,
    ];
  
    try {
      const docRef = doc(db, "purchasedTokens", user.nickname);
      await updateDoc(docRef, {
        claimedPoints: updatedClaimedPoints,
        prizesClaimed: updatedPrizes,
      });
  
      // Update state
      setCurrentUserforInfo((prev) => ({
        ...prev,
        claimedPoints: updatedClaimedPoints,
        prizesClaimed: updatedPrizes,
      }));
    } catch (error) {
      console.error("Error updating points and prizes:", error);
    }
  };
  
  

  const canSpin = () => {
    if (!currentUserforInfo) return false;
    const { totalPoints, claimedPoints = 0 } = currentUserforInfo;
    return totalPoints - claimedPoints >= SPIN_COST;
  };

  return (
    <>
      {!mustSpin && prizeNumber !== null && (
        <Confetti width={width} height={height} numberOfPieces={60} />
      )}
      <div className='charty' style={{ textAlign: "center", marginTop: "50px", position: "relative" }}>
        <img
          src={mustSpin ? spinon : prizeNumber !== null ? spinfin : spinoff}
          alt="Spin Button"
          className="spin-lu"
          onClick={canSpin() ? handleSpinClick : null}
          style={{ cursor: canSpin() ? "pointer" : "not-allowed", opacity: canSpin() ? 1 : 0.5 }}
        />
        <div style={{display: "flex", flexDirection: "column", textAlign: "left", paddingLeft: "18px"}}>
          <h1 className='newheader'>Spin the Wheel!</h1>
          <span className="text-points">Each spin is worth 20 points!</span>
          <span>
            Available Points: 
            <strong>
              {currentUserforInfo 
                ? (currentUserforInfo.totalPoints || 0) - (currentUserforInfo.claimedPoints || 0) 
                : 0}
            </strong>
          </span>
        </div>
        <div style={{ display: "inline-block", position: "relative" }}>
          {data.length > 0 ? (
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              onStopSpinning={() => {
                setMustSpin(false);
                updatePointsAndPrizes(); // Update points and prizes after spinning
              }}
              textColors={["#ffffff"]}
              outerBorderColor="#52376f"
              innerBorderColor="#52376f"
              outerBorderWidth={6}
              radiusLineColor="#52376f"
              radiusLineWidth={3}
              textDistance={70}
            />
          ) : (
            <p>Loading prizes...</p>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          {mustSpin ? <p>Spinning...</p> : ''}
        </div>
        {prizeNumber !== null && !mustSpin && (
          <div className="prize-display">
            <h3>Congratulations! <br />You won: {data[prizeNumber].option}</h3>
            <span className="rewardclaimtext">
              All prizes are claimable from your user{" "}
              <RouterNavLink
                to="/profile"
                className="subtitle-change"
                id="minter-btn"
                activeClassName="router-link-exact-active"
              >
                Profile
              </RouterNavLink>
              .
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(PrizeWheel, {
  onRedirecting: () => <Loading />,
});
