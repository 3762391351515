import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
// import moment from 'moment'
import NFDButton from "../utils/nfdbutton";
// import Highlight from "../components/Highlight";
import moment from 'moment';
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Button } from "reakit/Button";
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Gravatar from 'react-gravatar'
import checkInParams from '../check-code.json';
// import rewards from '../rewards.json';
import styled from "styled-components";
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";
import {
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import bonusRewards from '../bonus-rewards.json';
import Avatar from '../utils/avatar';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


export const dialogStyles = styled.div`
  transition: opacity 250ms ease-in-out, transFcone 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(-50%, -10%, 0) rotateX(90deg);
  &[data-enter] {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const CheckinComponent = () => {
  const { width, height } = useWindowSize();
  const dialog = useDialogState();
  // const checkInParams = [{ value: "beeryummy", date: 'March'}, { value: "beertummy", date: 'April'}];
  const [checkinStatus, setCheckinStatus] = useState(false);
  const [dontOwnStatus, setdontOwnStatus] = useState(false);
  const [levelRewards, setLevelRewards] = useState([]);
  let userId = '';
  let paramStatus = false;
  let holderStatus = false;
  let dupeSignIn = false;
  let nodupeSignIn = true;
  // const [tempOrigCheckins, settempOrigCheckins] = useState([]);
  const { user } = useAuth0();
  let params = new URLSearchParams(window.location.search);
  let paramyUrl = params.get('id');
  // let usersDbEntry = {};
  let tempUserDataCheckins = [];
  let docRef = {};
  let userWalletInfo = {};
  const nowTime = moment().format();
  const nowTimeFormatted = moment().format("MMM Do YYYY");
  // const currentMonth = moment(new Date()).format('MMMM');
  const currentDay = moment().day();
  const [newUserName, setNewUserName] = useState(
    {
      userName: ''
    }
  );
  const [levelInfo, setLevelInfo] = useState({});
  const [awards, setawards] = useState([]);


  useEffect(() => {
    // checkiningIn();
    getLevelRewards();
    return () => {};
  }, []);

  useEffect(() => {
    // once we loaded the level rewards -- we can do the user magic
    if (levelRewards.length > 0) {
      holderDoc();
    }
  }, [levelRewards]);

  // write function to call rewards fierbase
  const getLevelRewards = async () => {
    let tempRewardsArray = [];
    await getDocs(collection(db, 'levelRewards'))
    .then((result) => {
      result.forEach((doc) => {
        let item = doc.data();
        item.firebaseId = doc.id;
        tempRewardsArray.push(item);
      });
      tempRewardsArray.sort((a, b) => a.level - b.level);
      setLevelRewards(tempRewardsArray)
      // console.log('tempRewardsArray ', tempRewardsArray);
    });

  }

  const updateUser = async (walletInfo) => {
    await updateDoc(docRef, {
      metaData: user,
      status: 'checkedin'
    });
    setCheckinStatus(true);
    if (nodupeSignIn) {
      toast.success("SUCCESS: You checked in successfully!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } 
    if (dupeSignIn) {
      toast.info("Welcome back! We missed you too!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  // hawk this is where we are rewards level rewards
  const checkRewardStatus = async (tempUserData) => {
    const prevEarnedRewards = tempUserData.rewards;
    const prevEarnedHolderRewards = tempUserData.holderRewards;
    // const currentEarnedBonusRewards = tempUserData.tempEarnedBonusRewards;
    let currentRewards = awards;
    let currentBonusPoints = 0;
    let currentLevel = 0;

    // check to see if tempuserdata has nothing...
    // if so check the user.total value we just changed last function
    // console.log('currentLevel ', currentLevel);

    if (tempUserData.metaData) {
      // critera for lvl 2

      // loop through each reward
      levelRewards.forEach((item) => {
        // if 'checkins.length' >= the reward -- push reward
       
        if(user.totalPoints >= item.criteria) {
          // loop through prevSaved rewards
          prevEarnedRewards.forEach((alreadyAwarded) => {
             // see if this item exists..  
            if(alreadyAwarded.name === item.name) {
              // it does.. assign the correct
              item.claimed = alreadyAwarded.claimed || false;
            }
          })
          currentRewards.push(item);         
        }
        if(tempUserData.totalPoints === item.criteria) {
          // currentRewards.push(item);
          setLevelInfo(item)
          dialog.show();
        }
      });
      
      currentLevel = currentRewards.length;
      console.log('currentLevel ', currentLevel)
      updateRewards(currentRewards, currentLevel, tempUserData.tempEarnedBonusRewards);
    } else {
      // the dont have metadata -- assuming its their first visit
      currentLevel = 1;
      currentRewards.push(levelRewards[0]); 
      updateRewards(currentRewards, currentLevel, tempUserData.tempEarnedBonusRewards);
    }   
    
  };

  const updateRewards = async (torewards, level) => {
    console.log('torewards ', torewards)
    console.log('level ', level)
    console.log('user ', user)
    await updateDoc(docRef, {
      rewards: torewards,
      level: level,
      totalPoints: user?.totalPoints
    });
  };
    
  const getHoldersMinusCheck = async () => {
    docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    let tempUserData = [];
    if (docSnap.exists()) {
      tempUserDataCheckins = [];
      tempUserData = docSnap.data();
      userWalletInfo = tempUserData;
      if (userWalletInfo.checkins) {
        tempUserDataCheckins = tempUserData.metaData.checkins;
        // // tempUserDataCheckins.sort((a,b) => (a.metaData.totalCheckin < b.metaData.totalCheckin) ? 1 : ((b.metaData.totalCheckin < a.metaData.totalCheckin) ? -1 : 0));
        user.checkins = tempUserDataCheckins;
        user.totalCheckin = tempUserDataCheckins.length;
      }
      // updateUser(tempUserData);
    }
    
  }

  const holderDoc = async () => {
     // console.log('currentMonth ', currentDay);
     if (checkInParams.filter(e => e.value === paramyUrl && e.date === currentDay).length > 0) {
      paramStatus = true;
    }

    // if they own a token and they have the current param
    if (paramStatus === true) {
      docRef = doc(db, "purchasedTokens", user.nickname);
      const docSnap = await getDoc(docRef);
      let tempUserData = [];
      let userTempReanredRewards = [];
      if (docSnap.exists()) {
        tempUserDataCheckins = [];
        tempUserData = docSnap.data();
        userWalletInfo = tempUserData;
        if(userWalletInfo.userName) {
          const name = 'userName';
          setNewUserName(values => ({...values, [name]: userWalletInfo.userName}))
        }
        if(tempUserData.metaData?.email) {
          tempUserDataCheckins = tempUserData.metaData.checkins;
          tempUserDataCheckins.forEach((item) => {
            // item.formedDate = new Date
            const formedTime = moment(item).format("MMM Do YYYY");
            const formedNowTime = moment(nowTime).format("MMM Do YYYY");
            if(formedTime === formedNowTime) {
              dupeSignIn = true;
              nodupeSignIn = false;
            }
          });
        }
        // loop and check for doops
        user.id = user.nickname;
        if (dupeSignIn === false) {
          tempUserDataCheckins.push(nowTime);
        }
        tempUserDataCheckins.sort((a,b) => (a < b) ? 1 : ((b < a) ? -1 : 0));
        user.totalCheckin = tempUserDataCheckins.length;
        // user.holderPoints = tempUserData.holderPoints > 0 ? tempUserData.holderPoints : 0;
        // zero out a value to track earned points
        let totalEarnedPOintsFromPurchases = 0;
        // loop through all earned array
        if(tempUserData.earnedTotals?.length > 0) {
          tempUserData.earnedTotals.forEach((itemPurchased, ii) => {
            // add the total points to the running total
            totalEarnedPOintsFromPurchases = totalEarnedPOintsFromPurchases + itemPurchased.pointTotals;
          });
          // assign that total a user.value 
          user.holderPoints = totalEarnedPOintsFromPurchases;
        } else {
          user.holderPoints = 0;
        }
        
        user.checkins = tempUserDataCheckins;
        user.avatar = userWalletInfo.avatar === undefined ? 'Gravatar' : userWalletInfo.avatar;
        // var for totalpoints (wil eventually add checkins and earned points - can be saved to user)
        // user.totalPoints = 0;

        let totalingPoints = 0;
      
        // I need to update totalpoints var  add the points locafunctionvar + checkins
        user.totalPoints = user.holderPoints + user.totalCheckin;
        tempUserData.tempEarnedBonusRewards = userTempReanredRewards;
        updateUser(tempUserData);
        await checkRewardStatus(tempUserData);
      } else {
        // doc.data() will be undefined in this case
        // return console.log("No such document!");
        console.log('tell me nothing');
      }
      // if they own and dont have the rght param
    } else {
      // they are holder - but its the wrong code
      toast.error("ERROR: Please double check QR code!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return getHoldersMinusCheck(user.nickname);
    }
  };

  const acceptDialog = async () => {
    dialog.hide();
  };
  
  const checkiningIn = async () => {
    let tempTokenHolderArray = [];
    
    // we getting all the tokens
    getDocs(collection(db, 'purchasedTokens'))
    .then((result) => {
      result.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        tempTokenHolderArray.push(item);
      });
      
      // looping through and check if they are a holder already
      tempTokenHolderArray.forEach((holder) => {
        const emailHolder = holder.emailAddress.toLowerCase();
        const userEmail = user.email.toLowerCase();
       
        if (emailHolder === userEmail) {
          // USER HAS A TOKEN
          holderStatus = true;
          userId = holder.id;
          // console.log('paramyUrl ', paramyUrl);
          // console.log('currentMonth ', currentDay);
          if (checkInParams.filter(e => e.value === paramyUrl && e.date === currentDay).length > 0) {
            paramStatus = true;
            userId = holder.id;
          }
        }
      });

      // if they own a token and they have the current param
      if (holderStatus === true) {
        if (paramStatus === true) {
          return holderDoc(userId);
          // if they own and dont have the rght param
        } else {
          // they are holder - but its the wrong code
          toast.error("ERROR: Please double check QR code!", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return getHoldersMinusCheck(userId);
        }
      } else {
         // they are not holders
         // lets check if qr is right.. 
            // if it is -- lets create them a user in the DB and give it checkin  
        setdontOwnStatus(true);
        // console.log('dontOwnStatus', dontOwnStatus);
        return toast.error("ERROR: Doesn't look like you own a token!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

  
     
    });

  };

  return (
    <>
    <Confetti
      width={width}
      height={height}
      numberOfPieces={60}
    />
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left pad-top">
        <Col md={2} className="st-padding">
         <Avatar address={user.email} avatar={user.avatar ? user.avatar : undefined} />
        </Col>
        <Col md className="userstuff">
          {userWalletInfo.userName && 
            <h1 className={newUserName.userName && 'hidden'}>{ userWalletInfo.userName }</h1>
          }
          {newUserName && 
            <h1>{ newUserName.userName }</h1>
          }
          <h1 className={newUserName.userName && 'hidden'}>{user.nickname}</h1>
          {/* <p><strong>Total Checkins: </strong>{user.totalCheckin}</p> */}
        </Col>
        <Col md={4} className="center-that-buttt">
        {checkinStatus &&
          <div className="check-btn no-button" > 
           <FontAwesomeIcon icon="check" className="mr-2" />Check In Successful 
            <br />
           <span className="light-date">({nowTimeFormatted})</span>
          </div>
           }
        </Col>

        <Col md={4} className="center-that-but nopadside">
        {!dontOwnStatus &&
          <div className="pad-container nopadside">

              <br />
            <RouterNavLink
                to="/profile"
                className="subtitle-change"
                id="minter-btn"
                activeClassName="router-link-exact-active"
              >
                <NFDButton label="View Profile" icon="user" sublabel="Track checkins & rewards!" color="blue-btn" />
                {/* <button className="clicklink bluebut openseabdg">View Your Profile</button> */}
              </RouterNavLink>
          
          </div>
          }
          {dontOwnStatus && 
            <div className="pad-container">
            <h3 className="title-change" >You are a not a member! You need to scan the QR code and sign-up!</h3>
          </div>
          }
        </Col>
      </Row>
      
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
      <ToastContainer />
    
       
       <DialogBackdrop {...dialog}  className="backstyle">
            <Dialog className="refix" {...dialog} aria-label="Welcome">
              <h3 className="claimh zeropadbot">Level Up!</h3>
              <div className="leveler-div">
                <h1 className="grats">Congratulations!</h1>
                <label className="labeler abby-b">You earned a new reward!</label>
                <div className="imgholdy zero-top">
                    <div 
                      style={{
                        backgroundImage: `url(${levelInfo.image})`,
                        backgroundSize: "cover",
                      }}
                      className={'leader-rank newbadge newbig'}></div>
                  </div>
              </div>
              <Button className="closebtn" onClick={dialog.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
              <div className="flutarsky">
               
               <div className="form-holder-dog">
               <div className="holdertarsky bigtitle">
                    {/* <span className="lab">Reward Name:</span> */}
                    {levelInfo.name}
                    <p className="rewards-description zeropadbot">{levelInfo.description}</p>
                  </div> 
                
              </div>
              {!levelInfo.claimed && levelInfo.type === 'physical' &&
                  <div className="status-rew activer">REDEEMABLE</div>
                }

              <TwitterShareButton className="social-share zeropadtop" hashtags={['lucypackmagic', 'loyaltyrewards']} url="https://rewards.lucypackmagic.com/" title={'I just earned the "' + levelInfo.name + '" reward! '}>
                    <p className="clicklink linker close-numy share-twitty">Share on Twitter <TwitterIcon className="twitty" size={30} round={true} /></p>   
                  </TwitterShareButton>
              </div>
            
            </Dialog>
            </DialogBackdrop>
    </Container>
    </>
  );
};

export default withAuthenticationRequired(CheckinComponent, {
  onRedirecting: () => <Loading />,
});
