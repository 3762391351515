import React from 'react';
import prefixNames from '../prefilx-names.json';
import suffixNames from '../suffix-names.json';
import { Label } from 'reactstrap';

// Function to generate a random name
export function generateRandomName() {
  const prefix = prefixNames[Math.floor(Math.random() * prefixNames.length)];
  const suffix = suffixNames[Math.floor(Math.random() * suffixNames.length)];
  const randomNumber = Math.floor(10 + Math.random() * 90); // Generate a random 4-digit number

  return `${prefix}${suffix}${randomNumber}`;
}

// Component to display and regenerate the name
class NameGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generatedName: props.originalName
    };
  }

  regenerateName = () => {
    const newName = generateRandomName();
    this.setState({
      generatedName: newName
    });
    // Invoke the callback function with the new name
    this.props.onNameChange(newName);
  };

  render() {
    const { display, regenerateButton } = this.props;
    const { generatedName } = this.state;

    return (
      <div>
        {display && <Label for="userName">User Name:</Label>}
        {display && <p className='genname'>{generatedName}</p>}
        {regenerateButton && (
          <button className='genbut' type="button" onClick={this.regenerateName}>
            Generate New Username
          </button>
        )}
      </div>
    );
  }
}

export default NameGenerator;
