import React, { useState, useEffect } from "react";
import { Container, Input, Button } from "reactstrap";
import { Dialog, DialogBackdrop, useDialogState } from "reakit/Dialog";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"; // Import Firebase configuration
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrizeAdmin = () => {
  const [prizes, setPrizes] = useState([]);
  const [newPrize, setNewPrize] = useState({
    option: "",
    style: {
      backgroundColor: "#ffffff",
      textColor: "#000000",
    },
    image: null, // Image object
  });
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const dialogState = useDialogState();

  useEffect(() => {
    fetchPrizes();
  }, []);

  const fetchPrizes = async () => {
    try {
      const prizeList = [];
      const snapshot = await getDocs(collection(db, "prizes"));
      snapshot.forEach((doc) => {
        const data = doc.data();
        prizeList.push({ ...data, id: doc.id });
      });
      setPrizes(prizeList);
    } catch (error) {
      console.error("Error fetching prizes:", error);
    }
  };

  const handleImageUpload = async () => {
    if (!imageFile) return null;
  
    try {
      // Create a reference to the file
      const storageRef = ref(storage, `prizes/${imageFile.name}`);
  
      // Upload the file
      const uploadTaskSnapshot = await uploadBytes(storageRef, imageFile);
  
      // Get the download URL
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
  
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };
  

  const handleSavePrize = async (e) => {
    e.preventDefault();
    try {
      const imageUrl = await handleImageUpload();

      // Build the image object
      const imageObject = imageUrl
        ? {
            uri: imageUrl,
            offsetX: 0,
            offsetY: 0,
            sizeMultiplier: 1,
            landscape: false,
          }
        : null;

      const prizeData = {
        ...newPrize,
        image: imageObject,
      };

      if (selectedPrize) {
        const docRef = doc(db, "prizes", selectedPrize.id);
        await updateDoc(docRef, prizeData);
      } else {
        await addDoc(collection(db, "prizes"), prizeData);
      }

      setNewPrize({
        option: "",
        style: {
          backgroundColor: "#ffffff",
          textColor: "#000000",
        },
        image: null,
      });
      setSelectedPrize(null);
      setImageFile(null);
      fetchPrizes();
      dialogState.hide();
    } catch (error) {
      console.error("Error saving prize:", error);
    }
  };

  const handleEditPrize = (prize) => {
    setSelectedPrize(prize);
    setNewPrize(prize);
    dialogState.show();
  };

  const handleDeletePrize = async (id) => {
    try {
      const docRef = doc(db, "prizes", id);
      await deleteDoc(docRef);
      fetchPrizes();
    } catch (error) {
      console.error("Error deleting prize:", error);
    }
  };

  const closeModal = () => {
    setNewPrize({
      option: "",
      style: {
        backgroundColor: "#ffffff",
        textColor: "#000000",
      },
      image: null,
    });
    setSelectedPrize(null);
    setImageFile(null);
    dialogState.hide();
  };

  return (
    <Container>
      <Button onClick={dialogState.show}>Add Prize</Button>
      <div className="bonusholder">
        <div className="bonus-reward">
          <div className="table-title-th"><strong>Prize Name</strong></div>
          <div className="table-title-th"><strong>Background Color</strong></div>
          <div className="table-title-th"><strong>Text Color</strong></div>
          <div className="table-title-th"><strong>Image</strong></div>
          <div className="table-title-th"><strong>Actions</strong></div>
        </div>
        {prizes.map((prize) => (
          <div className="bonus-reward" key={prize.id}>
            <div>{prize.option}</div>
            <div>{prize.style.backgroundColor}</div>
            <div>{prize.style.textColor}</div>
            <div>
              {prize.image?.uri ? (
                <img
                  src={prize.image.uri}
                  alt={prize.option}
                  style={{ width: "50px", height: "50px" }}
                />
              ) : (
                "No Image"
              )}
            </div>
            <div>
              <span className="rightbtnpad" onClick={() => handleEditPrize(prize)}>
                <FontAwesomeIcon icon="edit" className="mr-3" />
              </span>
              <span className="mr-3 highz" onClick={() => handleDeletePrize(prize.id)}>
                <FontAwesomeIcon icon="trash" className="mr-3" />
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <DialogBackdrop {...dialogState} className="backstyle">
        <Dialog className="refix" {...dialogState} aria-label="Prize Modal">
          <h3>{selectedPrize ? "Update Prize" : "Add New Prize"}</h3>
          <Button className="closebtn" onClick={closeModal}>Close</Button>
          <form onSubmit={handleSavePrize}>
            <label className="full-width">
              Prize Name:
              <Input
                type="text"
                placeholder="Prize Name"
                value={newPrize.option}
                onChange={(e) => setNewPrize({ ...newPrize, option: e.target.value })}
              />
            </label>
            <label className="full-width">
              Background Color:
              <Input
                type="color"
                value={newPrize.style.backgroundColor}
                onChange={(e) =>
                  setNewPrize({
                    ...newPrize,
                    style: { ...newPrize.style, backgroundColor: e.target.value },
                  })
                }
              />
            </label>
            <label className="full-width">
              Text Color:
              <Input
                type="color"
                value={newPrize.style.textColor}
                onChange={(e) =>
                  setNewPrize({
                    ...newPrize,
                    style: { ...newPrize.style, textColor: e.target.value },
                  })
                }
              />
            </label>
            <label className="full-width">
              Image:
              <Input
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </label>
            <div className="avatar-saver padtoplg">
              <Button className="clicklink linker" type="submit">
                {selectedPrize ? "Update" : "Save"}
              </Button>
              <Button className="clicklink linker greygoose" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </form>
        </Dialog>
      </DialogBackdrop>
    </Container>
  );
};

export default PrizeAdmin;
