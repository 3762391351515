import React, { createContext, useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Import Firebase configuration

export const AvatarContext = createContext();

export const AvatarProvider = ({ children }) => {
  const [avatarList, setAvatarList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const avatars = [];
        const snapshot = await getDocs(collection(db, "avatars"));
        snapshot.forEach((doc) => {
          avatars.push(doc.data());
        });
        setAvatarList(avatars);
      } catch (error) {
        console.error("Error fetching avatars:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvatars();
  }, []);

  return (
    <AvatarContext.Provider value={{ avatarList, loading }}>
      {children}
    </AvatarContext.Provider>
  );
};
