import React from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import Qrcodes from "../src/check-code.json";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Qr from "./views/qr";
import Lobby from "./views/Lobby";
import AdminComponent from "./views/Admin";
import CheckLink from "./views/check-link";
import LobbyPurchase from "./views/LobbyPurchase";
import Profile from "./views/Profile";
import Checkin from "./views/Checkin";
import Partners from "./views/Partners";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import Game from "./Game";
import Redeem from "./views/Redeem";
import PrizeWheel from "./components/PrizeWheel";
import { AvatarProvider } from "./AvatarProvider";

// styles
import "./App.css";
import "./dapp.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

// Component to dynamically determine the className based on route
const AppWrapper = ({ children }) => {
  const location = useLocation(); // Hook to get current route

  // Dynamically set className based on route
  const getClassName = () => {
    if (location.pathname === "/scan") return "d-flex flex-column h-100 h-1000 qrcodepage";
    if (location.pathname === "/profile" || location.pathname === "/redeem") return "d-flex flex-column h-100 hnit"; // Exclude h-100
    if (location.pathname === "/") return "d-flex flex-column h-100 h-200"; // Add h-200 for the homepage
    return "d-flex flex-column h-100"; // Default case
  };

  return <div id="app" className={getClassName()}>{children}</div>;
};

const App = () => {
  const { isLoading, error } = useAuth0();
  const currentDay = moment().day();
  let currentUrl = "";
  Qrcodes.forEach((item) => {
    if (item.date === currentDay) {
      currentUrl = item.value;
    }
  });

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <AvatarProvider>
        <AppWrapper>
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/checkin" component={Checkin} />
              <Route path="/lobby" component={Lobby} />
              <Route exact path="/lobby-purchase/:userId" component={LobbyPurchase} />
              <Route path="/profile" component={Profile} />
              <Route path="/partners" component={Partners} />
              <Route path="/redeem" component={Redeem} />
              <Route path="/spin" component={PrizeWheel} />
              <Route path="/games" component={Game} />
              <Route path="/admin" component={AdminComponent} />
              <Route path="/scan" component={Qr} />
              <Route path={"/check-link" + currentUrl} component={CheckLink} />
            </Switch>
          </Container>
          <Footer />
        </AppWrapper>
      </AvatarProvider>
    </Router>
  );
};

export default App;
