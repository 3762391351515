import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useDialogState,
    Dialog,
    DialogBackdrop,
    DialogDisclosure,
  } from "reakit/Dialog";
import { Container, Input, Button } from "reactstrap";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this points to your Firebase setup

const BonusRewards = ({ bonusRewards, dialogBonusReward, selectRewardToEdit, removeRecord }) => {
  const addDialogState = useDialogState(); // For the "Add Bonus Reward" dialog
  const [newReward, setNewReward] = useState({ name: "", pointTotal: 0 });

  const handleAddReward = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "bonusRewards"), newReward); // Save to Firebase
      addDialogState.hide(); // Close dialog
      setNewReward({ name: "", pointTotal: 0 }); // Reset form
    } catch (error) {
      console.error("Error adding bonus reward:", error);
    }
  };

  return (
    <Container>
      {/* Add Bonus Reward Button */}
      <Button onClick={addDialogState.show} style={{ marginBottom: "10px" }}>
        Add Bonus Reward
      </Button>

      {/* Bonus Rewards Table */}
      <div className="bonusholder">
        <div className="bonus-reward">
          <div className="table-title-th"><strong>Reward Name</strong></div>
          <div className="table-title-th"><strong>Point Value</strong></div>
          <div className="table-title-th"><strong>Actions</strong></div>
        </div>
        {bonusRewards?.map((d, i) => (
          <DialogDisclosure
            className="open-dia"
            key={i + d.name}
            {...dialogBonusReward}
            onClick={() => selectRewardToEdit(d)}
          >
            <div className="bonus-reward">
              <div>{d.name}</div>
              <div>{d.pointTotal}</div>
              <div>
                <span className="rightbtnpad">
                  <FontAwesomeIcon icon="edit" className="mr-3" />
                </span>
                <span className="mr-3 highz" onClick={(e) => { removeRecord(e, d.firebaseId); }}>
                  <FontAwesomeIcon icon="trash" className="mr-3" />
                </span>
              </div>
            </div>
          </DialogDisclosure>
        ))}
      </div>

      {/* Add Bonus Reward Modal */}
      <DialogBackdrop {...addDialogState} className="backstyle">
        <Dialog className="refix" {...addDialogState} aria-label="Add Bonus Reward">
          <h3>Add New Bonus Reward</h3>
          <form onSubmit={handleAddReward}>
            <label className="full-width">
              Name:
              <Input
                type="text"
                value={newReward.name}
                onChange={(e) => setNewReward({ ...newReward, name: e.target.value })}
                placeholder="Enter reward name"
              />
            </label>
            <label className="full-width">
              Point Total:
              <Input
                type="number"
                value={newReward.pointTotal}
                onChange={(e) => setNewReward({ ...newReward, pointTotal: Number(e.target.value) })}
                placeholder="Enter point value"
              />
            </label>
            <div style={{ marginTop: "10px" }}>
              <Button type="submit" className="clicklink linker">Save</Button>
              <Button onClick={addDialogState.hide} className="clicklink linker greygoose">Cancel</Button>
            </div>
          </form>
        </Dialog>
      </DialogBackdrop>
    </Container>
  );
};

export default BonusRewards;
