import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from 'moment';
// import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { ToastContainer, toast } from 'react-toastify';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../firebase';
import { NavLink as RouterNavLink } from "react-router-dom";
import { collection, getDocs, addDoc, setDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import {
  useDialogState
} from "reakit/Dialog";
import { Button } from "reakit/Button";
import styled from "styled-components";
import Qrcodes from "../check-code.json";
import Gravatar from 'react-gravatar'
import NFDButton from "../utils/nfdbutton";
import Avatar from '../utils/avatar';
import { generateRandomName } from '../components/Namegenerator';

export const dialogStyles = styled.div`
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(-50%, -10%, 0) rotateX(90deg);
  &[data-enter] {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const CheckLinkComponent = () => {
  // const [checkinStatus, setCheckinStatus] = useState(false);
  // const [tempOrigCheckins, settempOrigCheckins] = useState([]);
  const [hideShit, sethideShit] = useState(false);
  const { user } = useAuth0();
  const [user2WalletInfo, set2userWalletInfo] = useState({});
  let docRef = {};
  let nametoUse = user.nickname;
  const [userWalletInfo, setuserWalletInfo] = useState({});
  const [hide2Shit, sethide2Shit] = useState(false);
  const [loadingStatus, setloadingStatus] = useState(false);
  const [newUserStatus, setnewUserStatus] = useState(false);
  const [urlString, setUrlString] = useState('');
  const [newUserName, setNewUserName] = useState('');
  // const nowTime = moment().format("MMM Do YYYY");
  // const currentMonth = moment(new Date()).format('MMMM');

  useEffect(() => {
    // gettingUserInfoFromFullList();
    getUser();
    return () => {};
  }, []);

  const getUser = async () => {
    const currentDay = moment().day();
    setloadingStatus(true);
    const docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const tempUserData = docSnap.data();
      if(tempUserData.userName) {
        sethide2Shit(true);
      }
      set2userWalletInfo(tempUserData);
      setloadingStatus(false);
    } else {
      addUser();
    }
     // hand qr bizness and unique link
     Qrcodes.forEach((item) => {
      // item.formedDate = new Date
      if(item.date === currentDay) {
      //   setcurrentQrInfo(item);
        setUrlString(item.value);
      };
    });
  }

  const addUser = async () => {
    const newUserName = generateRandomName();
    setNewUserName(newUserName);
    setDoc(doc(db, "purchasedTokens", user.nickname), {
      emailAddress: user.email.toLowerCase(),
      created: new Date(),
      userName: newUserName
    })
    .then((result) => {
      toast.success("SUCCESS: New user created successfully!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setloadingStatus(false);
    });

    

  }

  

  const gettingUserInfoFromFullList = async () => {
    setloadingStatus(true);
    let tempTokenHolderArray = [];
    let tempUserData = []
    let validHolder = false;
    const currentDay = moment().day();

    
    getDocs(collection(db, 'purchasedTokens'))
    .then((result) => {
      result.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        tempTokenHolderArray.push(item);
      });
      
      tempTokenHolderArray.forEach((holder) => {
        tempUserData = holder;
        const formedEmail = holder.emailAddress.toLowerCase();
        if (formedEmail === user.email) {
          // USER HAS A TOKEN
          if(holder.userName) {
            sethide2Shit(true);
          }
          validHolder = true;
          set2userWalletInfo(tempUserData);
        }
      });

      if(validHolder === false) {
        addDoc(collection(db, 'purchasedTokens'), {
          emailAddress: user.email.toLowerCase(),
          created: new Date()
        })
        .then((result) => {
          setnewUserStatus(true);
          toast.success("SUCCESS: New user created successfully!", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setloadingStatus(false);
      });
      } else {
        setloadingStatus(false);
      }

     
    });
    
    // hand qr bizness and unique link
    Qrcodes.forEach((item) => {
      // item.formedDate = new Date
      if(item.date === currentDay) {
      //   setcurrentQrInfo(item);
        setUrlString(item.value);
      };
    });

  };


  return (
    <Container className="mb-5 overwrite">
      {loadingStatus && 
        <Loading />
      }
      <Row className="align-items-center profile-header mb-5 text-center text-md-left pad-top">
        <Col md className="profile-cont shange">
          <br />
        <Avatar address={user.email} avatar={user2WalletInfo.avatar ? user2WalletInfo.avatar : undefined} />
          {user2WalletInfo.userName && 
            <h3 className="nameheader">Hey { user2WalletInfo.userName }!</h3>
          }
          <h3 className={hide2Shit ? 'hidden' : 'nameheader'}>Hi {newUserName ?? user.nickname}</h3>
          {newUserStatus && 
            <div className="newusermsg">
              <h3 className="thankyouheader">Thank you for joining NFD Rewards!</h3>
              <p className="thankyoumsg">To start collecting rewards, click the button below.</p> 
            </div>
          }
          <RouterNavLink
            to={"/checkin?id=" + urlString}
            className="hrefcheck"
            id="minterbtn"
            activeClassName="router-link-exact-active"
          >
             {/* <button className="clicklink bluebut openseabdg" >Check In</button> */}
   
            <NFDButton label="Check In" icon="check-circle" sublabel="Get your points!" color="blue-btn" />

          </RouterNavLink>
          <br />
           {/* <a className="open-link-holder" href='https://hulmeville-checkin.web.app/checkin?id=beeristhebest' target="_blank" rel="noopener noreferrer">Click Here to check-in</a> */}
        </Col>
      </Row>
      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
      <ToastContainer />
    </Container>
  );
};

export default withAuthenticationRequired(CheckLinkComponent, {
  onRedirecting: () => <Loading />,
});
