import React, { useEffect, useState } from "react";
import { Container, Input, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";
import { Button } from "reakit/Button";
import { collection, getDoc, doc, addDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
// import NFDButton from "../utils/nfdbutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { db } from '../firebase';
import AvatarAdmin from '../components/AvatarAdmin'; // Import the AvatarAdmin component
import BonusRewards from '../components/BonusRewardsAdmin'; // Import the BonusRewards component
import LevelRewards from '../components/LevelRewardsAdmin'; // Import the LevelRewards component
import PrizeWheelAdmin from '../components/PrizeWheelAdmin'; // Import the PrizeWheelAdmin component
import UsersAdmin from '../components/UsersAdmin'; // Import the UsersAdmin component
import RewardAdmin from '../components/RewardAdmin'; // Import the UsersAdmin component

const AdminComponent = () => {
  const [adminAllowed, setadminAllowed] = useState(false);
  const [activeTab, setActiveTab] = useState("bonusRewards"); // Default to Bonus Rewards tab
  const [bonusRewards, setBonusRewards] = useState([]);
  const [currentLevelReward, setCurrentLevelReward] = useState({});
  const [levelRewards, setLevelRewards] = useState([]);
  const [currentBonusReward, setCurrentBonucReward] = useState({});
  const [name, setName] = useState('');
  const [pointTotal, setPointTotal] = useState('');
  const { user } = useAuth0();
  let docRef = {};
  const dialogBonusReward = useDialogState();
  const dialogLevelReward = useDialogState();

  const [levelName, setLevelName] = useState('');
  const [levelCriteria, setLevelCriteria] = useState('');
  const [levelDescription, setLevelDescription] = useState('');
  const [levelLevel, setLevelLevel] = useState(0);
  const [levelReward, setLevelReward] = useState('');
  const [levelType, setLevelType] = useState('');

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    docRef = doc(db, "purchasedTokens", user.nickname);
    const docSnap = await getDoc(docRef);
    let tempUserData;
    if (docSnap.exists()) {
      tempUserData = docSnap.data();
      if(tempUserData.admin) {
        // set true
        setadminAllowed(true);
        getBonusRewards();
        getLevelRewards();
      }
    }

  }

  const getBonusRewards = async () => {
    let tempRewardsArray = [];
    getDocs(collection(db, 'bonusRewards'))
    .then((result) => {
      result.forEach((doc) => {
        let item = doc.data();
        item.firebaseId = doc.id;
        tempRewardsArray.push(item);
      });
      tempRewardsArray.sort((a, b) => a.id - b.id);
      setBonusRewards(tempRewardsArray)
      // console.log('tempRewardsArray ', tempRewardsArray);
    });

  }

  const selectRewardToEdit = (reward) => {
    setCurrentBonucReward(reward);
    setName(reward.name);
    setPointTotal(reward.pointTotal);
  } 

  const removeRecord = async (event, recordId) => {
    event.stopPropagation();
    let selectIndex = bonusRewards.findIndex((bonusReward) => bonusReward.firebaseId === recordId);
    let updatedArray = JSON.parse(JSON.stringify(bonusRewards));
    try {
      const docRefer = doc(db, 'bonusRewards', recordId);
      await deleteDoc(docRefer);
      updatedArray.splice(selectIndex, 1); 

      setBonusRewards(updatedArray);
      // yupdate array hawk
    } catch (error) {
      console.error('Error removing record: ', error);
    }
  };

  const getLevelRewards = async () => {
    let tempRewardsArray = [];
    getDocs(collection(db, 'levelRewards'))
    .then((result) => {
      result.forEach((doc) => {
        let item = doc.data();
        item.firebaseId = doc.id;
        item.id = doc.id;
        tempRewardsArray.push(item);
      });
      tempRewardsArray.sort((a, b) => a.level - b.level);
      setLevelRewards(tempRewardsArray)
      // console.log('tempRewardsArray ', tempRewardsArray);
    });

  }

  const handleSubmit = async (e) => {
    // point total must be higher than one set it on button
    e.preventDefault();
    let updatedArray = JSON.parse(JSON.stringify(bonusRewards));
    let selectIndex = 0;
    let newfirebaseId = null;
    // Create a new bonus reward object
    if(currentBonusReward.id) {
      // we need to find out which one they are submitting
      selectIndex = bonusRewards.findIndex((bonusReward) => bonusReward.id === currentBonusReward.id);
      // we need to update that item in setCurrentBonucReward with the correct new values
      updatedArray[selectIndex].name = name;
      updatedArray[selectIndex].pointTotal = pointTotal;
      updateRecord(updatedArray[selectIndex])
      
    } else {
      const newBonusReward = {
        name: name,
        pointTotal: pointTotal,
        id: updatedArray.length,
        total: 0,
        image: '',
        category: 'admin-added',
        daysExpire: null,
        criteria: 0,
        reward: ''
      };
      newfirebaseId = await addNewRecord(newBonusReward);
      newBonusReward.firebaseId = newfirebaseId;
      updatedArray.push(newBonusReward)
    }
    setBonusRewards(updatedArray);
    dialogBonusReward.hide();
    // Clear the form inputs
    setCurrentBonucReward({});
    setName('');
    setPointTotal('');
  };

  const handleClear = () => {
    // Clear the form inputs
    setName('');
    setPointTotal('');
    setCurrentBonucReward({});
    dialogBonusReward.hide();
  };

  const updateRecord = async (record) => {
    // console.log('record ', record);
    const stdocRef = doc(db, "bonusRewards", record.firebaseId);
    await updateDoc(stdocRef, {
     record
     })
    .then((result) => {
      // toast.success("SUCCESS: Updated reward!", {
      //   position: "top-right",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined
      // });
    });
  }
  const addNewRecord = async (data) => {
    try {
      const pdocRef = await addDoc(collection(db, 'bonusRewards'), data);
      // console.log('Document added with ID: ', pdocRef.id);
      // hit a function to remove the state
      return pdocRef.id;
    } catch (error) {
      console.error('Error adding document: ', error);
      return null;
    }
  };

  const removeLevelRecord = async (event, recordId) => {
    event.stopPropagation();
    // console.log(recordId)
    let selectIndex = levelRewards.findIndex((levelReward) => levelReward.firebaseId === recordId);
    let updatedArray = JSON.parse(JSON.stringify(bonusRewards));
    try {
      const docRefer = doc(db, 'levelRewards', recordId);
      // console.log('deleteing ', recordId);
      await deleteDoc(docRefer);
      updatedArray.splice(selectIndex, 1); 

      setBonusRewards(updatedArray);
      // yupdate array hawk
    } catch (error) {
      console.error('Error removing record: ', error);
    }
  };

  const addLevel = async () => {
    dialogLevelReward.show();
  }

  const selectLevelRewardToEdit = (reward) => {
    // console.log('rewards ', reward);
    setCurrentLevelReward(reward);
    // setName(reward.name);
    // setPointTotal(reward.pointTotal);

    setLevelName(reward.name)
    setLevelCriteria(reward.criteria);
    setLevelDescription(reward.description);
    setLevelLevel(reward.level);
    setLevelReward(reward.reward);
    setLevelType(reward.type);
  } 

  const addRewardToDB = async (rewardData) => {
    try {
      // Add the reward data to the 'levelRewards' collection in Firestore
      const docRef = await addDoc(collection(db, "levelRewards"), rewardData);
  
      // Return the newly generated document ID
      return docRef.id;
    } catch (error) {
      console.error("Error adding reward to the database:", error);
      return null;
    }
  };
  

  const closeModal = () => {
    dialogBonusReward.hide();
    dialogLevelReward.hide();
    // Clear the form inputs
    setName('');
    setPointTotal('');
    setCurrentBonucReward({});

  }

  const handleSaveLevel = async () => {
    // point total must be higher than one set it on button
   //  e.preventDefault();
    let updatedArray = JSON.parse(JSON.stringify(levelRewards));
    let selectIndex = 0;
    let newfirebaseId = null;
    // Create a new bonus reward object
    if(currentLevelReward.rid != undefined) {
      // we need to find out which one they are submitting
      selectIndex = levelRewards.findIndex((levelReward) => levelReward.rid === currentLevelReward.rid);
      // we need to update that item in setCurrentBonucReward with the correct new values
      updatedArray[selectIndex].name = levelName;
      updatedArray[selectIndex].criteria = levelCriteria;
      updatedArray[selectIndex].description = levelDescription;
      updatedArray[selectIndex].level = levelLevel;
      updatedArray[selectIndex].reward = levelReward;
      updatedArray[selectIndex].type = levelType;
      updateLevelRecord(updatedArray[selectIndex])
      
    } else {
      const newLevelReward = {
       name: levelName,
       icon: "",
       image: "",
       level: levelLevel,
       criteria: levelCriteria,
       reward: levelReward,
       description: levelDescription,
       claimed: false,
       rid: levelRewards.length,
       type: levelType
      };
      newfirebaseId = await addRewardToDB(newLevelReward);
      newLevelReward.firebaseId = newfirebaseId;
      updatedArray.push(newLevelReward)
    }
    setLevelRewards(updatedArray);
    dialogLevelReward.hide();
    // Clear the form inputs
    setCurrentLevelReward({});
    setLevelCriteria(0);
    setLevelName('')
    setLevelDescription('');
    setLevelReward('');
    setLevelType('');
 }

 const updateLevelRecord = async (record) => {
  // console.log('save record ', record);
  const stdocRef = doc(db, "levelRewards", record.firebaseId);
  await updateDoc(stdocRef, {
   ...record
   })
  .then((result) => {
    // toast.success("SUCCESS: Updated reward!", {
    //   position: "top-right",
    //   autoClose: 1500,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined
    // });
  });
}

  const renderTabContent = () => {
    switch (activeTab) {
      case "bonusRewards":
        return <BonusRewards
              bonusRewards={bonusRewards}
              dialogBonusReward={dialogBonusReward}
              selectRewardToEdit={selectRewardToEdit}
              removeRecord={removeRecord}
            />
            ;
      case "levelRewards":
        return <LevelRewards
          key={levelRewards}
          levelRewards={levelRewards}
          dialogLevelReward={dialogLevelReward}
          selectLevelRewardToEdit={selectLevelRewardToEdit}
          removeLevelRecord={removeLevelRecord}
          addLevel={addLevel}
        />;
      case "avatars":
        return <AvatarAdmin />;
      case "prizeWheel":
        return <PrizeWheelAdmin />;
      case "users":
        return <UsersAdmin />;
      case "rewards":
        return <RewardAdmin />;
      default:
        return <BonusRewards
          bonusRewards={bonusRewards}
          dialogBonusReward={dialogBonusReward}
          selectRewardToEdit={selectRewardToEdit}
          removeRecord={removeRecord}
        />
        ;
    }
  };

  return (
    <>
      <Container className="text-center shero my-5 leadery mb-5 bodesign">
        <h1 className="mb-4 admin-title">Admin</h1>
        {!adminAllowed && (
          <p style={{ color: "white" }}>
            Sorry nothin' to see here! <span>(you need admin rights)</span>
          </p>
        )}
        {adminAllowed && (
          <>
            <Nav tabs className="admin-tabs">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "bonusRewards" })}
                  onClick={() => setActiveTab("bonusRewards")}
                >
                  Bonus Points
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "levelRewards" })}
                  onClick={() => setActiveTab("levelRewards")}
                >
                  Level Badges
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "avatars" })}
                  onClick={() => setActiveTab("avatars")}
                >
                  Avatars
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "prizeWheel" })}
                  onClick={() => setActiveTab("prizeWheel")}
                >
                  Spin Prizes
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "users" })}
                onClick={() => setActiveTab("users")}
              >
                Users
              </NavLink>
            </NavItem>
              <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "rewards" })}
                onClick={() => setActiveTab("rewards")}
              >
                Rewards
              </NavLink>
            </NavItem>
            </Nav>
            <div className="tab-content">{renderTabContent()}</div>
          </>
        )}
      </Container>
      <DialogBackdrop {...dialogBonusReward}  className="backstyle">
     <Dialog className="refix" {...dialogBonusReward} aria-label="Welcome">
       <h3 className="claimh claimg"> {currentBonusReward.firebaseId ? 'Update ' : 'Add New ' } Bonus Reward</h3>
       <Button className="closebtn" onClick={(e) => closeModal()}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
       <div className="flutarsky">
        <div className="childtarsky">
        <form onSubmit={handleSubmit}>

      

          <label className="full-width">
            Name:
            {/* <input type="text" value={name} onChange={(e) => setName(e.target.value)} /> */}
            <Input type="text" name="rewardName" id="rewardName" value={name}  onChange={(e) => setName(e.target.value)} />
          </label>
          {/* <br /> */}
          <label className="full-width">
            Point Total:
            <Input type="number" name="pointTotal" id="pointTotal" value={pointTotal}  onChange={(e) => setPointTotal(e.target.value)} />
            {/* <input type="number" value={pointTotal} onChange={(e) => setPointTotal(e.target.value)} /> */}
          </label>
          {/* <br /> */}
          <div className="avatar-saver padtoplg">
            <Button className="clicklink linker" style={{marginTop: '0px'}} disabled={false} type="submit">SAVE</Button>
            <Button className="clicklink linker greygoose" style={{marginTop: '0px'}} onClick={() => handleClear()}>CANCEL</Button>
          </div>
        </form>
        </div>
       </div>
     
     </Dialog>
     </DialogBackdrop>

     <DialogBackdrop {...dialogLevelReward}  className="backstyle">
     <Dialog className="refix" {...dialogLevelReward} aria-label="Welcome">
       <h3 className="claimh claimg"> {currentLevelReward.firebaseId ? 'Update ' : 'Add New ' } Level Reward</h3>
       <Button className="closebtn" onClick={(e) => closeModal()}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
       <div className="flutarsky">
        <div className="childtarsky">
        <form onSubmit={handleSubmit}>

      

          <label className="full-width">
            Level Reward Name:
            {/* <input type="text" value={name} onChange={(e) => setName(e.target.value)} /> */}
            <Input type="text" name="rewardName" id="rewardName" value={levelName}  onChange={(e) => setLevelName(e.target.value)} />
          </label>
          {/* <br /> */}
          <label className="full-width">
            Point Criteria:
            <Input type="number" name="levelCriteria" id="levelCriteria" value={levelCriteria}  onChange={(e) => setLevelCriteria(e.target.value)} />
            {/* <input type="number" value={pointTotal} onChange={(e) => setPointTotal(e.target.value)} /> */}
          </label>
          {/* <br /> */}
          <label className="full-width">
            Description:
            <Input type="text" name="levelDescription" id="levelDescription" value={levelDescription}  onChange={(e) => setLevelDescription(e.target.value)} />
            {/* <input type="number" value={pointTotal} onChange={(e) => setPointTotal(e.target.value)} /> */}
          </label>
          {/* <br /> */}
          <label className="full-width">
            Level Number:
            <Input type="number" name="levelLevel" id="levelLevel" value={levelLevel}  onChange={(e) => setLevelLevel(e.target.value)} />
            {/* <input type="number" value={pointTotal} onChange={(e) => setPointTotal(e.target.value)} /> */}
          </label>
          {/* <br /> */}
          <label className="full-width">
            Reward:
            <Input type="text" name="levelReward" id="levelReward" value={levelReward}  onChange={(e) => setLevelReward(e.target.value)} />
            {/* <input type="number" value={pointTotal} onChange={(e) => setPointTotal(e.target.value)} /> */}
          </label>
          {/* <br /> */}
          <label className="full-width">
            Type:
            <Input type="text" name="levelType" id="levelType" value={levelType}  onChange={(e) => setLevelType(e.target.value)} />
            {/* <input type="number" value={pointTotal} onChange={(e) => setPointTotal(e.target.value)} /> */}
          </label>         
          {/* <br /> */}
          <div className="avatar-saver padtoplg paddybot">
            <Button className="clicklink linker" style={{marginTop: '0px'}} disabled={false} onClick={() => handleSaveLevel()}>SAVE</Button>
            <Button className="clicklink linker greygoose" style={{marginTop: '0px'}} onClick={() => handleClear()}>CANCEL</Button>
          </div>
        </form>
        </div>
       </div>


     </Dialog>
     </DialogBackdrop>
    </>
    
  );
};

export default withAuthenticationRequired(AdminComponent, {
  onRedirecting: () => <div>Loading...</div>,
});
