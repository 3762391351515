import React, { useState, useEffect } from "react";
import { Container, Input, Button } from "reactstrap";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase"; // Import Firebase configuration
import Avatar from "../utils/avatar"; // Reuse the Avatar component if needed

const UsersAdmin = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersList = [];
      const snapshot = await getDocs(collection(db, "purchasedTokens"));
      snapshot.forEach((doc) => {
        const data = doc.data();
        usersList.push({ ...data, id: doc.id });
      });
      setUsers(usersList);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAdminToggle = async (userId, isAdmin) => {
    try {
      const userRef = doc(db, "purchasedTokens", userId);
      await updateDoc(userRef, { admin: !isAdmin });

      // Update state locally
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, admin: !isAdmin } : user
        )
      );
    } catch (error) {
      console.error("Error updating admin status:", error);
    }
  };

  return (
    <Container>
      <div className="bonusholder">
        <div className="bonus-reward">
          <div className="table-title-th"><strong>Avatar</strong></div>
          <div className="table-title-th"><strong>User Name</strong></div>
          <div className="table-title-th"><strong>Email</strong></div>
          <div className="table-title-th"><strong>Admin</strong></div>
        </div>
        {users.map((user) => (
          <div className="bonus-reward" key={user.id}>
            <div>
              <Avatar
                avatar={user.avatar || ""} // Assuming `avatar` field exists
                address={user.emailAddress || "default@example.com"}
                size="small"
              />
            </div>
            <div>{user.userName || "Unknown User"}</div>
            <div>{user.emailAddress || "No Email"}</div>
            <div>
              <Input
                type="checkbox"
                checked={!!user.admin}
                onChange={() => handleAdminToggle(user.id, user.admin)}
              />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default UsersAdmin;
