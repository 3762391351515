import React, { useContext, useEffect, useState } from "react";
import Gravatar from "react-gravatar";
import { AvatarContext } from "../AvatarProvider";

const Avatar = ({ avatar, address, size, minus, active }) => {
  const { avatarList } = useContext(AvatarContext);
  const [matchedAvatar, setMatchedAvatar] = useState(null);
  const [isBlank, setIsBlank] = useState(false);

  useEffect(() => {
    if (avatarList?.length > 0) {
      if (!avatar || avatar === "") {
        setIsBlank(true)
      }else {
        setIsBlank(false)
      }
      const match = avatarList.find(item => item.type === avatar);
      setMatchedAvatar(match);
    }
  }, [avatar, avatarList]);

  return (
    <div className={`${minus && "minus"}`}>
      {/* Render Gravatar based on type */}
      {(avatar === "Gravatar" || isBlank) && (
        <Gravatar email={address} className={`avatar-img final-ava ${size}`} />
      )}
      {avatar === "MonsterId" && (
        <Gravatar
          default="monsterid"
          email={address}
          className={`avatar-img final-ava ${size}`}
        />
      )}

      {/* Render image if found in avatarList */}
      {matchedAvatar?.image && (
        <img
          src={matchedAvatar.image}
          alt={matchedAvatar.name}
          className={`final-ava ${size}`}
        />
      )}
    </div>
  );
};

export default Avatar;
