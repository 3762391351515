import React, { useState, useEffect } from "react";
import { Container, Input, Button } from "reactstrap";
import { Dialog, DialogBackdrop, useDialogState } from "reakit/Dialog";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"; // Import Firebase configuration
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from '../utils/avatar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AvatarAdmin = () => {
  const [avatars, setAvatars] = useState([]);
  const [newAvatar, setNewAvatar] = useState({
    type: "",
    name: "",
    criteria: 0,
    hide: false,
    image: null,
    date: null, // Add a date field
  });
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const dialogState = useDialogState();

  useEffect(() => {
    fetchAvatars();
  }, []);

  const fetchAvatars = async () => {
    try {
      const avatarList = [];
      const snapshot = await getDocs(collection(db, "avatars"));
      snapshot.forEach((doc) => {
        const data = doc.data();
        // Convert the stored date string to a Date object
        const date = data.date ? new Date(data.date) : null;
        avatarList.push({ ...data, date, id: doc.id });
      });
      setAvatars(avatarList);
    } catch (error) {
      console.error("Error fetching avatars:", error);
    }
  };

  const handleImageUpload = async () => {
    if (!imageFile) return null;
    const storageRef = ref(storage, `avatars/${imageFile.name}`);
    await uploadBytes(storageRef, imageFile);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleEditAvatar = (avatar) => {
    setSelectedAvatar(avatar);
    setNewAvatar(avatar);
    dialogState.show();
  };

  const handleDateChange = (date) => {
    setNewAvatar((prev) => ({ ...prev, date }));
  };


  const handleSaveAvatar = async (e) => {
    e.preventDefault();
    try {
      const imageUrl = await handleImageUpload();
      const avatarData = {
        ...newAvatar,
        image: imageUrl || newAvatar.image,
      };
  
      // Remove the `date` property if it's null
      if (!newAvatar.date) {
        delete avatarData.date;
      } else {
        avatarData.date = newAvatar.date.toISOString(); // Convert to string if a date exists
      }
  
      if (selectedAvatar) {
        const docRef = doc(db, "avatars", selectedAvatar.id);
        await updateDoc(docRef, avatarData);
      } else {
        await addDoc(collection(db, "avatars"), avatarData);
      }
  
      setNewAvatar({ type: "", name: "", criteria: 0, hide: false, image: null, date: null });
      setSelectedAvatar(null);
      setImageFile(null);
      fetchAvatars();
      dialogState.hide();
    } catch (error) {
      console.error("Error saving avatar:", error);
    }
  };
  

  const handleDeleteAvatar = async (id) => {
    try {
      const docRef = doc(db, "avatars", id);
      await deleteDoc(docRef);
      fetchAvatars();
    } catch (error) {
      console.error("Error deleting avatar:", error);
    }
  };

  const closeModal = () => {
    setNewAvatar({ type: "", name: "", criteria: 0, date: null, hide: false, image: null });
    setSelectedAvatar(null);
    setImageFile(null);
    dialogState.hide();
  };

  return (
    <Container>
    <Button onClick={dialogState.show}>Add Avatar</Button>
    <div className="bonusholder">
      <div className="bonus-reward">
        <div className="table-title-th"><strong>Avatar Image</strong></div>
        <div className="table-title-th"><strong>Name</strong></div>
        <div className="table-title-th"><strong>Type</strong></div>
        <div className="table-title-th"><strong>Criteria</strong></div>
        <div className="table-title-th"><strong>Date</strong></div>
        <div className="table-title-th"><strong>Actions</strong></div>
      </div>
      {avatars.map((avatar) => (
        <div className="bonus-reward" key={avatar.id}>
          <Avatar avatar={avatar.type} address={"default@example.com"} size="small" />
          <div>{avatar.name}</div>
          <div>{avatar.type}</div>
          <div>{avatar.criteria}</div>
          <div>{avatar.date ? avatar.date.toLocaleDateString() : "N/A"}</div>
          <div>
            <span className="rightbtnpad" onClick={() => handleEditAvatar(avatar)}>
              <FontAwesomeIcon icon="edit" className="mr-3" />
            </span>
            <span className="mr-3 highz" onClick={() => handleDeleteAvatar(avatar.id)}>
              <FontAwesomeIcon icon="trash" className="mr-3" />
            </span>
          </div>
        </div>
      ))}
    </div>

    {/* Modal */}
    <DialogBackdrop {...dialogState} className="backstyle">
      <Dialog className="refix" {...dialogState} aria-label="Avatar Modal">
        <h3>{selectedAvatar ? "Update Avatar" : "Add New Avatar"}</h3>
        <Button className="closebtn" onClick={closeModal}>Close</Button>
        <form onSubmit={handleSaveAvatar}>
          <label className="full-width">
            Type:
            <Input
              type="text"
              placeholder="Avatar Type"
              value={newAvatar.type}
              onChange={(e) => setNewAvatar({ ...newAvatar, type: e.target.value })}
            />
          </label>
          <label className="full-width">
            Name:
            <Input
              type="text"
              placeholder="Avatar Name"
              value={newAvatar.name}
              onChange={(e) => setNewAvatar({ ...newAvatar, name: e.target.value })}
            />
          </label>
          <label className="full-width">
            Criteria:
            <Input
              type="number"
              placeholder="Criteria"
              value={newAvatar.criteria}
              onChange={(e) => setNewAvatar({ ...newAvatar, criteria: Number(e.target.value) })}
            />
          </label>
          <label className="full-width">
            Date:
            <DatePicker
              selected={newAvatar.date}
              onChange={handleDateChange}
              isClearable
              placeholderText="Select a date"
              dateFormat="yyyy-MM-dd"
            />
          </label>
          {/* <label className="full-width">
            Hide:
            <Input
              type="checkbox"
              checked={newAvatar.hide}
              onChange={(e) => setNewAvatar({ ...newAvatar, hide: e.target.checked })}
            />
          </label> */}
          <label className="full-width">
            Image:
            <Input
              type="file"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
          </label>
          <div className="avatar-saver padtoplg">
            <Button className="clicklink linker" type="submit">{selectedAvatar ? "Update" : "Save"}</Button>
            <Button className="clicklink linker greygoose" onClick={closeModal}>Cancel</Button>
          </div>
        </form>
      </Dialog>
    </DialogBackdrop>
  </Container>
  );
};

export default AvatarAdmin;
